import Cookies from 'universal-cookie';
import { Table } from "reactstrap";
function ModuloDevuelveCertificados (props) {
  const cookies=new Cookies();
var cantidad=1;
console.log(props)
    const usuarioComun = (
<>

     </>
    );
    const usuarioPrivilegios = (

      <>
<Table className="table-primary" bordered  size="sm">
<tr >
      <th>
        #
      </th>
      <th>
      Identificación
      </th>
      <th>
        Apellido y Nombre
      </th>
     

    </tr>
     
    {!props.cosas?"":props.cosas.length>0?props.cosas.map((cosas) =><>

<tr   key={cosas.id} >

<th scope="row">
{cantidad++}

</th>

<th>
{cosas.dniCertificado}
</th>
<th>
{ cosas.apellidoNombre}
</th>


<button className='btn btn-success' onClick={()=>props.myID(cosas)}>
 Imprimir </button>
</tr>
</>):""}
      </Table></>

  );  
  return (
    <div>
      
     {usuarioPrivilegios}
   
    </div>
  );
};
export default ModuloDevuelveCertificados;
