import React from 'react';
import {Document, Page, View,Text, PDFViewer,Image} from '@react-pdf/renderer';
import Logo from '../imagenes/escudoIseP.png'
import Cookies from 'universal-cookie';
import ObtieneDatosCookiesReciboExpediente from './obtieneDatosCookiesReciboExpediente';
import devuelveDependenciaConstultada from '../tools/devuelveDependencia';
import regresaBienFechaYDia from '../tools/regresaBienFechayDia';
import devuelveSedeConsultada from '../tools/devuelveSede'


function Recibo() {
 

  const cookies=new Cookies();
 
  console.log(ObtieneDatosCookiesReciboExpediente() )

  const datosVarios=  ObtieneDatosCookiesReciboExpediente() 
 
       return (
      <PDFViewer showToolbar="false" style={{width:"100%",height:"100vh"}} >
      <Document  title={"Recibo expediente "+datosVarios.expedienteID+" de fecha: "+regresaBienFechaYDia(datosVarios.fechaEntrada)}>
        <Page size="A4" style={{"display":"grid"}}>
        <View id="Escudo"style={{alignSelf:"center", height:"35px", width:"35px", marginTop:"10px"}}>
        <Image src={Logo}/></View>
        <View style={{marginTop:"35px"}}>
        <Text id='nombre escuela' style={{alignSelf:"center", marginTop:"-35px",fontSize:"10px"}}>
          Instituto de Seguridad Pública</Text>
          <View style={{marginLeft:"10px",marginTop:"65px",lineHeight:"2px"}}>
          <Text id='Cuerpo3' style={{alignSelf:"flex-start", fontSize:"10px"}}>
          ID_Recibo:{datosVarios.idRecibo}</Text>
          <Text id='Cuerpo' style={{alignSelf:"left",marginTop:"35px", margin:"5px",fontSize:"18px"}}>
          Recibí de {devuelveDependenciaConstultada().filter(dendencias=>dendencias.id==datosVarios.destinoSalidaID)[0].descripcion+"("+devuelveSedeConsultada().filter(sede=>sede.id==datosVarios.sedeSalidaID)[0].descripcion+")"+" "}expediente identificado en el SEISeP: "{datosVarios.expedienteID+""}" con el cómputo de {"("+datosVarios.fojasActuales + ") fojas útiles"} en fecha {regresaBienFechaYDia(datosVarios.fechaEntrada) +" horas. "}
          Observacion: {datosVarios.comentario!="null"?datosVarios.comentario:""}.- </Text>
          <Text style={{alignSelf:"flex-end", marginRight:"115px",fontSize:"12px"}}>{datosVarios.usuarioRecibe}</Text>
          <Text id='Cuerpo3' style={{alignSelf:"flex-end", marginRight:"115px",fontSize:"12px"}}>
         
          {devuelveDependenciaConstultada().filter(dendencias=>dendencias.id==datosVarios.destinoEntradaID)[0].descripcion+"("+devuelveSedeConsultada().filter(sede=>sede.id==datosVarios.sedeEntradaID)[0].descripcion+")"}</Text>
          <Text>

          </Text>
          
        </View>   <Text id='Cuerpo3' style={{alignSelf:"flex-start", fontSize:"18px"}}>
          ------------------------------------------------------------------------------------------------------------------</Text> </View>
       
     </Page>
    
    
        
       



  

      </Document>
      </PDFViewer>

    );
  }
export default Recibo