import Cookies from 'universal-cookie';
const cookies =new Cookies;

const seteaCokieExpediente=async(respuesta)=>{

    cookies.set("idUsuarioExpediente",respuesta.usuarioID, {path:"/"}) 
    cookies.set("idSedeExpediente",respuesta.sedeID, {path:"/"}) 
    cookies.set("dependenciaIdExpediente",respuesta.dependenciaID, {path:"/"}) 
    cookies.set("idExpediente",respuesta.id, {path:"/"}) 
    cookies.set("tieneExptAnterior",respuesta.tieneExptAnterior, {path:"/"}) 
    cookies.set("idExptAnterior",respuesta.idExptAnterior, {path:"/"}) 
    cookies.set("idExptPosterior",respuesta.idExptPosterior, {path:"/"}) 
    cookies.set("iniciador",respuesta.iniciador, {path:"/"}) 
    cookies.set("idCategoria",respuesta.idCategoria, {path:"/"}) 
    cookies.set("concepto",respuesta.concepto, {path:"/"}) 
    cookies.set("idExpMinisterio",respuesta.idExpMinisterio, {path:"/"}) 
    cookies.set("fechaMinisterio",respuesta.fechaMinisterio, {path:"/"})
    cookies.set("fechaGeneracion",respuesta.createdAt, {path:"/"})

      
}
export default seteaCokieExpediente;