import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import regresaBienFecha from '../tools/regresaBienFecha';
//*import './GestorUsuariosCalificaciones.css'
import Modal from './Modal.jsx';
import Check from './Check.jsx'
import CreaInstancia from './CrearInstancia.jsx';
const UserManagement = () => {
  const [instancias, setInstancias] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const { register, handleSubmit, reset } = useForm();
  const [modalAbierto, setModalAbierto] = useState(false);


const customStyles = {
    content: {
        
      top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '400px', // Ancho del modal
    height: '600px', // Alto del modal
  
    },
  };

const devuelveInstancias=async(estado)=>{
  if(!estado){
    const resultado= await axios.post('https://wwwa.isepsantafe.edu.ar/expedientes/calificaciones/devuelveInstancias/')
if(resultado){

setInstancias(resultado.data.instanciaDevuelta)
}

  }else{
    const resultado= await axios.post('https://wwwa.isepsantafe.edu.ar/expedientes/calificaciones/devuelveInstancias?estado='+estado)
    if(resultado){
     
      setInstancias(resultado.data.instanciaDevuelta)
    }
  }
 
}

  useEffect(() => {
    // Fetch users from the API
    axios.post('https://wwwa.isepsantafe.edu.ar/expedientes/calificaciones/devuelveInstancias/')
      .then(response => {
        console.log(response.data.instanciaDevuelta)
        setInstancias(response.data.instanciaDevuelta)
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

  const handleEditClick = async(id,estadoInstancia) => {
    var estadoRespuesta=""
    if(estadoInstancia=="Activo"){
      estadoRespuesta="Anulado"
    }else{
            estadoRespuesta="Activo"
    }

    const resultado= await axios.post('https://wwwa.isepsantafe.edu.ar/expedientes/calificaciones/actualizaInstancia/'+id,{estado:estadoRespuesta})
    if(resultado){
    
      cierraModal()
    }
  };

  const onSubmit = (data) => {
    axios.put(`/api/users/${data.dni}`, data)
      .then(response => {
        // Update the local state
        setInstancias(instancias.map(user => user.id === data.id ? data : user));
        setSelectedUser(null);
      })
      .catch(error => {
        console.error('Error updating user:', error);
      });
  };
const closeModalInformeDocentes=()=>{
    setModalAbierto(false)
}
const cierraModal=()=>{
  devuelveInstancias("Activo")
  setModalAbierto(false)

}

const openModal = () => {
  setModalAbierto(true);
};


  return (
    <>
   <div > 
    <button onClick={()=>setModalAbierto(true)} >Crear Instancia</button>
    <Modal isOpen={modalAbierto} onClose={cierraModal}><CreaInstancia Instancias={instancias} myID={()=>cierraModal()}/></Modal>
   
   </div>
    <div>
      <h1>Instancias Evaluativas</h1>
      <button onClick={()=>devuelveInstancias(null)}>Ver Todas las Instancias</button>
      <button onClick={()=>devuelveInstancias("Activo")}>Ver Instancias Activas</button>
      <table>
        <thead>
          <tr>
            <th style={{width:"150px"}}>Instancia</th>
            <th style={{width:"150px"}}>Curso</th>
            <th>Fecha Inicial</th>
            <th>Fecha Final</th>
            <th>Estado</th>
          </tr>
        </thead>
        <tbody>
          {instancias.map(instantia => (
            <tr key={instantia.id}>
             <td>{instantia.nombreInstancia}</td>
             <td>{instantia.nombreCurso}</td>
              <td>{regresaBienFecha(instantia.fechaInicio) }</td>
              <td>{regresaBienFecha(instantia.fechaFinal)}</td>
              <td>{instantia.estado}</td>

              <td>
                {instantia.estado=="Activo"?<>  <button onClick={() => handleEditClick(instantia.id,instantia.estado)}>Anular</button></>:<>  <button onClick={() => handleEditClick(instantia.id,instantia.estado)}>Restaurar</button></>}
              
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedUser?()=>setModalAbierto(true):""}
    </div></>
  );
};

export default UserManagement;
