import { useForm } from 'react-hook-form';
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import Cookies from 'universal-cookie';
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario.js';
import devuelveCategorias from '../modulos/devuelveCategorias.js';
import { useState } from 'react';
import swal from 'sweetalert'
import convierteCambioSede from './convierteCambioSede.js'




function ModalCambiaSede (props) {
  const cookies=new Cookies();

  const ID=cookies.get('id');
  const [modalCadeteIsOpen, setCadeteIsOpen] = useState(false);
  const customStyles = {
    content: {/*
      top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width:"auto",
    height:"auto"
 */
    },
  };

  function openModalCadete() {
    setCadeteIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    
  }
  function closeModalCadete() {
    setCadeteIsOpen(false);
 
  }
  var cierro=false;
  const fecha = new Date();
const hoy = "Fecha: "+fecha.toLocaleDateString();

  
const parametro=props.datosCadete
const usuario=ObtieneDatosCookies()


  const store=async (data)=>{
    const client = axios.create({
      baseURL: "https://wwwa.isepsantafe.edu.ar/expedientes/cadetes"
      });
   const respuesta=await client.post('/cambiaDeSede/',convierteCambioSede(data))
   if(respuesta){
    queHago(respuesta.data)
   }

}

  const queHago=async(respuesta)=>{
    console.log(respuesta)
    if(respuesta.message==="Registro creado correctamente!!"){
      swal("Exito en el cambio de Sede de "+parametro.cadeteDni).then(()=>props.myID("false"))
      cierro=true;
    
    }
    else{
      swal("Algo Fallo!, consulte con el area Tecnica error A007!!!")
     
    
       }}


  const {register, formState: { errors }, handleSubmit,} = useForm({
  defaultValues: parametro//recupero lo que pasa por parametros
  });
  

const categorias=devuelveCategorias()

  const procesandoLtb = (datoLtb) => {

    store(datoLtb)

 
  }

//Aca enpieza el react lo anterior es javascript y hooks


  return (
    <>
    <div  >
      <form  onSubmit={handleSubmit(procesandoLtb)}>
       

<div class="row">
<div class='visually-hidden' >

          <div class="form-floating mb-3">
                  <input value ={parametro.cadeteDni} type="text" class="form-control" id="floatingInput" placeholder="cadeteDni" 
         {...register('cadeteDni', {
       
          })}/>
        <label for="floatingInput">DNI Cadete</label>
    </div>
      <div class="form-floating mb-3" >
      <input value ={usuario.id}type="text" class="form-control" id="floatingNombres" placeholder="idUsuario" 
      {...register('idUsuario', {
      
      })}/>
      <label for="floatingNombres">Identificacion Usuario</label>
    </div>
    
    <div class="form-floating mb-3">
      <input value={usuario.sedeID}type="number" class="form-control" id="floatingUsuario" placeholder="Sede Usuario" 
      {...register('idSede', {
      
      })}/>
      <label for="floatingUsuario">Sede ID</label>
    </div>
  
</div>

<div class="row">

          <div class='input-group-text' >
          <div class="form-floating mb-3">
  <select class="form-select" id="floatingCadeteGenero" aria-label="CadeteGenero"
  {...register('idSedeCambiada', {
    required: true
  })}
  >
   
    <option class="mb-3" value="ROSARIO">ROSARIO</option>
    <option class="mb-3" value="RECREO">RECREO</option>
    <option class="mb-3" value="MURPHY">MURPHY</option>
    <option class="mb-3" value="RECONQUISTA">RECONQUISTA</option>
    <option class="mb-3" value="RAFAELA">RAFAELA</option>
    
    
  </select>
  <label for="form-floating mb-3">Sede a la que Cambia</label>
</div>
<div class="form-floating mb-3">
  <input value={parametro.cadeteSede} class="form-control" id="floatingCadeteGenero" aria-label="CadeteGenero"
  {...register('cadeteSede', {
    required: true
  })}
  />
   
   
  <label for="form-floating mb-3">Sede Actual</label>
</div>
<div class="form-floating mb-3" style={{width:"210px"}}>
      <input type="date" class="form-control" id="floatingfechafechaCambio" placeholder="fechafechaCambio" 
      {...register('fechaCambio', {
        required: true
      })}/>
      <label for="floatingfechaCambio">Fecha de Autorizacion</label>
    </div> 
<div class="form-floating mb-3">
  <input value={parametro.promocion} class="form-control" placeholder="CURSO" id="floatingCurso" 
   {...register('motivo', {
    

  })}/>
  <label for="floatingCurso">Promocion</label>
</div>
     
<div>
  
</div>
</div>

<div class='card-header mb-3' >
    
<div class="form-floating">
  <textarea class="form-control" style={{height:"250px",resize:"none"}} placeholder="Descripcion de la solicitud" id="floatingDescripcion" 
   {...register('descripcion', {
    required: true,
    maxLength:1000,
 
  })}></textarea>
  <label for="floatingDescripcion">Descripcion</label>
  
</div>    
<input type="submit" value="Confirmar el cambio de Sede"  className="btn btn-success" />
<input type="button" value="Cancelar"  className="btn btn-danger" onClick={()=>props.myID("dale")}/>
</div>


</div>

</div>
 </form>

   
        
        </div>
 
        </>
  )}

export default ModalCambiaSede;
