import ObtieneDatosCookies from "../tools/obtieneDatosCookiesUsuario";

const convierteLogAnulaPresentePostulante=(data,proceso)=>{
  const usuario=ObtieneDatosCookies()
      
    return {
            idInscripto:data,
            idUsuario:usuario.id,
            etapa:proceso.etapa.nombreEtapa,
            proceso:proceso.proceso.proceso
                 
            
    }
}
export default convierteLogAnulaPresentePostulante;