import ObtieneDatosCookies from "../tools/obtieneDatosCookiesUsuario";

const convierteLicEsp=(informacion)=>{
 
  const usuario=ObtieneDatosCookies()
    
    

    return {

            cadeteDni:informacion.cadeteDni,
            idUsuario:usuario.id,
            idSede:usuario.sedeID,
            motivo:informacion.motivo,
            descripcion:informacion.descripcion,
            promocion:informacion.promocion,
            resolucionLicEsp:informacion.resolucionLicEsp,
            fechaLicEsp:informacion.fechaLicEsp
                       
    }
}
export default convierteLicEsp;