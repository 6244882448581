import Cookies from 'universal-cookie';
import devuelveSede from '../tools/devuelveSede';
import { Table } from "reactstrap";
import obtieneDatos  from '../tools/obtieneDatosCookiesUsuario'
function ModuloDevuelveDatosDocentes (props) {
  const cookies=new Cookies();
  const Usuario=obtieneDatos();
var DatosDocente= props.Datos

if(Usuario.destino=="1"||Usuario.destino=="7"||Usuario.destino=="10"){
  DatosDocente= props.Datos
}
else if(Usuario.destino=="11"){

  DatosDocente= props.Datos.filter(x=>x.Escuela=="Educacion a Distancia"&&x.sedeCursada==devuelveSede().filter(x=>x.id==Usuario.sedeID)[0].descripcion)
}
else if(Usuario.destino=="12"||Usuario.destino=="16"){
  DatosDocente= props.Datos.filter(x=>x.Escuela=="Escuela de Policia")
}
else if(Usuario.destino=="13"||Usuario.destino=="17"){
  DatosDocente= props.Datos.filter(x=>x.Escuela=="Escuela Superior")
}
else if(Usuario.destino=="14"||Usuario.destino=="18"){
  DatosDocente= props.Datos.filter(x=>x.Escuela=="Escuela de Especialidades")
}
else if(Usuario.destino=="15"||Usuario.destino=="19"){
  DatosDocente= props.Datos.filter(x=>x.Escuela=="Escuela de Investigaciones")
}
 
var contador=1

  const usuario= (

 <>
 <div><h4>Inasistencias Registradas: {props.Inasistencias.length}</h4></div>
 <h5>Materias Actuales en su escuela:</h5>
 <Table className="table-primary" bordered  size="sm">
 <tr style={{fontSize:"15px"}}>
       <th style={{width:"50px"}}>
         #
       </th>
       <th style={{width:"80px"}}>
        Division
       </th>
       <th style={{width:"200px"}}>
      Materia
       </th>
       <th>
       Escuela
       </th>
       <th>
       Horas
       </th>
       <th>
      
       </th>
     </tr>

    
 {!DatosDocente?"":DatosDocente.map((cosas) =><>

  <tr  key={cosas.id} className="aa"style={{fontSize:"12px"}} >

  <th scope="row"  >
  
 {contador++}

  </th>
  
  <th >
  {cosas.Division}
  </th>  
 {cosas.esRentada=="SI"?<> <th>
  
  {cosas.Materia}
  </th>  
 </>:<><th style={{color:"red"}}>{
 cosas.Materia+" (S. Ordinario)"}
 </th> </> 
 }
  <th>
  {cosas.Escuela}
  </th>  
  <th style={{textAlign:"center"}}>
  {cosas.Horas}
  
  </th>
  <th className="btn btn-success"style={{fontSize:"10px",justifySelf:"center"}}onClick={()=>props.myID(cosas)} >Crear Inasistencia</th>
  </tr>
</>)}

   
      
       </Table> </>
 )
    
  return (
    <div>


     {usuario}
   
    </div>
  );
};
export default ModuloDevuelveDatosDocentes;
