import { useForm } from 'react-hook-form';
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import Cookies from 'universal-cookie';
import ModuloEditaUsuarios from './editaUsuario';
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario';
import ModuloDevuelveUsuarios from './devuelveUsuarios';
import { useState } from 'react';
import FooterCorp from './footerCorp';
import Modal from 'react-modal';
import ModuloCreaUsuario from './creaUsuario'
function ModuloUsuarios () {
const cookies=new Cookies();
const navitate = useNavigate();
const ID=cookies.get('id');

const[modaCreaUsuario, setModalCreaUsuario]=useState(false)
const[usuariosVarios, setUsuariosVarios]=useState(null)
const[estaOK, setEstaOK]=useState(false)
const [usuarioSeleccionado,setUsuario]=useState({})
const [modalEditaUsuarioIsOpen, setModalEditaUsuarioIsOpen] = useState(false);


  if(!ID){
     window.location.href='./';
 }
 const URI='https://wwwa.isepsantafe.edu.ar/expedientes/usuarios/'
const usuarioLoguead=ObtieneDatosCookies()
 const {register, formState: { errors }, watch, handleSubmit,} = useForm({
  defaultValues: usuarioSeleccionado//recupero lo que pasa por parametros
  });
  const getUsuarios=async ()=>{
   try {
   await axios.post(URI).then((response)=>{
      setUsuariosVarios(response.data);setEstaOK(true)
    })
       } catch (error) {
    
      }
     }
  const volver=()=>
  {
    navitate('/usuarioLogueado')
  }
 
  const customStyles = {
    content: {
     width:"350px",heigth:"650px",
   zIndex:"10",textAlign:"center"
 
    },
  };
  
  if(!estaOK){
    getUsuarios()
  }
 
const eligeUsuario= (value)=>{

 openModalEditaUsuario();

setUsuario(value)

}
const CambiaContrasena=()=>{
  navitate('/cambioContrasena')
}


function openModalEditaUsuario() {
  setModalEditaUsuarioIsOpen(true);
}

function afterOpenModal() {
  // references are now sync'd and can be accessed.
  
}
function closeModalEditaUsuario() {
  setModalEditaUsuarioIsOpen(false);

}
  return (
    <div>
      
      <div style={{textAlign:"center"}}className="titulo">
      <Modal
      ariaHideApp={false}

        isOpen={modalEditaUsuarioIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalEditaUsuario}
        style={customStyles}
   
      >
       <div className=" btn-group"> <button className="btn btn-danger" onClick={closeModalEditaUsuario}>x </button> <h7 style={{alignSelf:"center"}}><strong>Edita Usuario </strong></h7>
      </div>
      <ModuloEditaUsuarios datos={usuarioSeleccionado} myID={(value2)=>closeModalEditaUsuario()} />
        
         
      </Modal>
      <Modal
      ariaHideApp={false}

        isOpen={modaCreaUsuario}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalEditaUsuario}
        style={customStyles}
   
      >
       <div className=" btn-group"> <button className="btn btn-danger" onClick={()=>setModalCreaUsuario(false)}>x </button> <h7 style={{alignSelf:"center"}}><strong>Crea Usuario </strong></h7>
      </div>
      <ModuloCreaUsuario myID={(value2)=>{setModalCreaUsuario(false);getUsuarios()}} />
        
         
      </Modal>
        <h2 >Modulo de Usuarios</h2>

      </div>
     <div style={ { "display": "flex", "justify-content": "center"}}>
      {usuarioLoguead.nivel<5&&(<button onClick={CambiaContrasena} className='btn btn-primary'>Cambiar Mi Contraseña</button>)}
      </div>
<hr />
<div style={{display:"flex", justifyContent:"center"}}>
 
{usuarioLoguead.nivel<=2&&estaOK&&(<> {usuarioLoguead.destino=="7"?<button className='btn btn-success'onClick={()=>setModalCreaUsuario(true)}>Crear Usuario</button>:""}<ModuloDevuelveUsuarios  cosas={usuarioLoguead.nivel>1?usuariosVarios.filter(x=>x.nivel>1):usuariosVarios} myID={(value)=>eligeUsuario(value)}/></>)}
</div>
      
        <section style={{textAlign:"center"}}>
        
        <input type="button" value="volver" onClick={volver} className="btn btn-warning"/>
        
        <FooterCorp />
        </section>
      
     
    
    </div>
  );
};
export default ModuloUsuarios;
