const devuelveCategorias=()=>{
    let Categorias=[
        {"id":1,"descripcion":"Baja"},
        {"id":2,"descripcion":"Reincorporacion"},
        {"id":3,"descripcion":"Designacion"},
        {"id":4,"descripcion":"Recurso"},
        {"id":5,"descripcion":"Apelacion en Subsidio"},
        {"id":6,"descripcion":"Pago Docente"},
        {"id":7,"descripcion":"Solicitud de Servicio Externo"},
        {"id":8,"descripcion":"Notificacion Externa"},
        {"id":9,"descripcion":"Nota de Pedido"},
        {"id":10,"descripcion":"Notas Varias"},
        {"id":11,"descripcion":"Nacional"},
        {"id":12,"descripcion":"LTB"},
        {"id":13,"descripcion":"Información Sumaria"},
        {"id":14,"descripcion":"Sumario Administrativo"},
        {"id":15,"descripcion":"Oficio Judicial"},
        {"id":16,"descripcion":"Credenciales"},
        {"id":17,"descripcion":"Capacitaciones"},
        {"id":18,"descripcion":"Cambio de Sede"}
        
    ]
   
    Categorias.sort(function (a, b) {
        return a.descripcion.localeCompare(b.descripcion);
      });
        return Categorias
    }
    export default devuelveCategorias