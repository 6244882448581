export const ordeno=(array, propiedad)=>{
try {
    
    return array.sort((a, b) => {
        if (a[propiedad].toLowerCase() < b[propiedad].toLowerCase()) {
            return -1;
        }
        if (a[propiedad].toLowerCase() > b[propiedad].toLowerCase()) {
            return 1;
        }
        return 0;
    });
    
} catch (error) {
    return array
}
  
  
  }