import React from 'react';
import { useDropzone } from 'react-dropzone';
import Papa from 'papaparse';

const CsvDropzone = (props) => {

    
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: '.csv',
    onDrop: (acceptedFiles) => {
      handleFile(acceptedFiles[0]);
    },
  });

  const handleFile = (file) => {
    Papa.parse(file, {
      complete: (results) => {
      
       props.datos(results.data)
        // Aquí puedes procesar los datos como desees
      },
      header: true, // Usa esto si tu CSV tiene una fila de encabezado
    });
  };

  return (
    <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
      <input {...getInputProps()} />
      {isDragActive ? (
        <p>Suelta el archivo aquí...</p>
      ) : (
        <p>Arrastra y suelta un archivo CSV aquí, o haz clic para seleccionar uno</p>
      )}
    </div>
  );
};

export default CsvDropzone;
