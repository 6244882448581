import Cookies from 'universal-cookie';
import { Table } from "reactstrap";
import regresaBienFechaYDia from '../tools/regresaBienFechayDia';
function ModuloDevuelvePresentes (props) {
  const cookies=new Cookies();
  const devuelveSede=()=>{
   
    let sedes=[
        {"id":"1","descripcion":"Provincial"},
        {"id":"2","descripcion":"Rosario"},
        {"id":"3","descripcion":"Recreo"},
        {"id":"4","descripcion":"Reconquista"},
        {"id":"5","descripcion":"Rafaela"},
        {"id":"6","descripcion":"Murphy/Melincue"},
       
    
    ]
    
        
        return  sedes
    }
  var cantidad2=1;
  var cantidad1=1;
    const usuarioComun = (
<>
 
 <Table className="table-primary" bordered  size="sm">
 <tr >
       <th>
         #
       </th>
       <th>
         DNI
       </th>
       <th>
       Apellido
       </th>
       <th>
       Nombres
       </th>
       <th>
       Genero
       </th>
       <th>
       Sede
       </th>
       <th>
       Fecha
       </th>

     </tr>
      
       {!props.cosas?"":props.cosas.map((cosas) =><>
  
       <tr   key={cosas.id} >
    
       <th scope="row">
      {cantidad1++}
      
       </th>
       
       <th>
       {cosas.dniInscripto}
       </th>  <th>
       { cosas.apellidoInscripto}
       </th>
       <th>
       { cosas.nombresInscripto}
       </th>
       
       <th>
       { cosas.generoInscripto}
       </th>
       <th>
       {"("+devuelveSede().filter(categoria=>categoria.id==cosas.idSede)[0].descripcion?"("+devuelveSede().filter(categoria=>categoria.id==cosas.idSede)[0].descripcion+""+")":""}
       </th>
       <th>
       { regresaBienFechaYDia(cosas.fechaPresente)}
       </th>
      
      
       </tr>
 </>)}
       </Table></>
 )
    const usuarioPrivilegios = (

      <>
 
<Table className="table-primary" bordered  size="sm">
<tr >
      <th>
        #
      </th>
      <th>
        DNI
      </th>
      <th>
      Apellido
      </th>
      <th>
      Nombres
      </th>
      <th>
      Genero
      </th>
      <th>
      Sede
      </th>
      <th>
      Fecha
      </th>
      {cookies.get('destino',{path:"/"})==9||cookies.get('destino',{path:"/"})==7?<th>
       Editar
      </th>:""}
    </tr>
     
      {!props.cosas?"":props.cosas.map((cosas) =><>
 
      <tr   key={cosas.id} >
   
      <th scope="row">
     {cantidad2++}
     
      </th>
      
      <th>
      {cosas.dniInscripto}
      </th>  <th>
      { cosas.apellidoInscripto}
      </th>
      <th>
      { cosas.nombresInscripto}
      </th>
      
      <th>
      { cosas.generoInscripto}
      </th>
      <th>
      {"("+devuelveSede().filter(categoria=>categoria.id==cosas.idSede)[0].descripcion?"("+devuelveSede().filter(categoria=>categoria.id==cosas.idSede)[0].descripcion+""+")":""}
      </th>
      <th>
      { regresaBienFechaYDia(cosas.fechaPresente)}
      </th>
     
      {cookies.get('destino',{path:"/"})==9||cookies.get('destino',{path:"/"})==7?<button className='btn btn-danger' onClick={()=>props.myID(cosas)}>   
       X </button>:""}
      </tr>
</>)}
      </Table></>

 

  );  
  return (
    <div>
      
     {cookies.get('nivel',{path:"/"})<=3?usuarioPrivilegios:usuarioComun}
   
    </div>
  );
};
export default ModuloDevuelvePresentes;
