import Cookies from 'universal-cookie';
const cookies =new Cookies;

const seteaCokieReciboExpediente=async(respuesta)=>{

cookies.set("idRecibo",respuesta.idRecibo, {path:"/"}) 
    cookies.set("usuarioEntradaID",respuesta.usuarioEntradaID, {path:"/"}) 
    cookies.set("destinoEntradaID",respuesta.destinoEntradaID, {path:"/"}) 
    cookies.set("sedeEntradaID",respuesta.sedeEntradaID, {path:"/"}) 
    cookies.set("sedeSalidaID",respuesta.sedeSalidaID, {path:"/"}) 
    cookies.set("destinoSalidaID",respuesta.destinoSalidaID, {path:"/"}) 
    cookies.set("expedienteID",respuesta.expedienteID, {path:"/"}) 
    cookies.set("fechaEntrada",respuesta.fechaEntrada, {path:"/"}) 
    cookies.set("comentario",respuesta.comentario, {path:"/"}) 
    cookies.set("fojasActuales",respuesta.fojasActuales, {path:"/"}) 
    cookies.set("usuarioRecibe",respuesta.usuarioRecibe, {path:"/"}) 
    
    
}
export default seteaCokieReciboExpediente;