import { useForm } from 'react-hook-form';
import axios from "axios";
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario.js';
import devuelveCategorias from '../modulos/devuelveCategorias.js';
import convierteExpediente from  '../adaptadores/convierteExpediente.js'
import destinodevuelveDependenciaConstultada from '../tools/devuelveDependencia.js'
import swal from 'sweetalert';


function FormEditaExpte (props) {
    
  const fecha = new Date();
const hoy = "Fecha: "+fecha.toLocaleDateString();

  const URI='https://wwwa.isepsantafe.edu.ar/expedientes/'
  
const parametro=props.expedienteAEditar
const usuario=ObtieneDatosCookies()
  

  const actualizaExpediente=async (data)=>{

   await axios.put(URI+"expedientes/actualiza/"+data.id,convierteExpediente(data)).then(f=>swal({
      title: f.data.message,
      closeOnClickOutside: false,  buttons: {
       
        confirm: "Aceptar"}
    })).then(()=>{props.myID("dale")})
 
   
   }


  const {register, formState: { errors }, watch, handleSubmit,} = useForm({
  defaultValues: parametro//recupero lo que pasa por parametros
  });
  
const categorias=devuelveCategorias()

  const onSubmit = (data) => {

    actualizaExpediente(data)
 
  }
 
  const fechaMinima = '1995-07-08';
  const fechaMaxima = '2005-07-07';
  const titulo =
    'Generacion de Expedientes ';
  const subTitulo = 'del Instituto de Seguridad Publica';
  
  


//Aca enpieza el react lo anterior es javascript y hooks


  return (
    <div>
     
  
  <div style={{display:'flex',flexWrap:"wrap",textAlign:"center",gridRowEnd:"-moz-initial",width:"100%",padding:"15px",margin:"10px",backgroundColor:"#005588", color:"#ffeeee"}}>
         
      <h3>Usuario Logueado:{" "+usuario.apellido +", "+usuario.nombre} </h3>
      <h3 style={{marginLeft:"20px"}}>{hoy}</h3>
        <h3 style={{marginLeft:"20px"}}>Division:{destinodevuelveDependenciaConstultada().filter(categoria=>categoria.id==usuario.destino)[0].descripcion} </h3>            </div><div>
          <hr/>
      <form className='form__formulario' onSubmit={handleSubmit(onSubmit)}>
        <section className="datos-personales">
 
         
 

          <div class='input-group-text' >
          <div class="form-floating mb-3 ml-3">
          {errors.iniciador?.type === 'required'&&(<>{alert("Ingrese el nombre del titular")}</>)}
          {errors.iniciador?.type === 'minLength' && (<>{alert("Es muy corto el nombre")}</>)}
          <input  type="text" class="form-control" id="floatingIniciador" placeholder="iniciador" 
          {...register('iniciador', {
          maxlength:"1024",
          required: true,
          minLength: 3,
          })}/>
          <label for="floatingIniciador">Causante</label>
          </div>
          <div class="form-floating mb-3">
          <select class="form-select" id="floatingidCategoria" aria-label="idCategoria"
          {...register('idCategoria', {
           required: true
           })}>
            {categorias.map((cate) =>
            <><option key={cate.id} value={cate.id}>
            {cate.descripcion}
            </option>
            </>
            )}    
    
    
  </select>
  <label for="floatingmotivo">Categoria del Expediente</label>
</div>
<div class="form-floating mb-3 ml-3">
 
          <select class="form-select"value={props.expedienteAEditar.tieneExptAnterior} style={{minWidth:"300px"}} disabled id="tieneExptAnterior" aria-label="tieneExptAnterior"
          {...register('tieneExptAnterior', {
           required: true
           })}>
               <option value="Sistema ISeP">Sistema ISeP</option>
            <option value="Manual">Manual</option>
            <option selected value="NO">NO</option>
    
    
  </select>
  <label for="tieneExptAnterior">Tiene Exp ISeP Anterior</label></div>


  <div class="form-floating mb-3">

          <input  type="text" value={props.expedienteAEditar.idExptAnterior}disabled  style={{minWidth:"320px"}} class="form-control" id="idExptAnterior" placeholder="idExptAnterior" 
          {...register('idExptAnterior', {

          })}/>
          <label for="floatingIniciador">Codigo de Expediente o Nota Precedente</label>
          </div></div>

            
                   
          
          <div>
            
        
          </div>
          <div>
       
          <div class='input-group-text' >
          <div class="form-floating mb-3"style={{width:"120px"}}>
         
         <input type="number" class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('fojasIniciales', {
           minLength: 1,
           required:true
          
         })}/>
         <label for="floatingIniciador">Fojas Iniciales</label>
         </div>
          
         <div class="form-floating mb-3"style={{width:"150px"}} >
      <input type="date" class="form-control" id="floatingfechaInicial" placeholder="fechaInicial" 
      {...register('fechaAnterior', {
        minLength: 3,
      })}/>
      <label for="floatingfechaMinisterio">Fecha Anterior?</label>
    </div>
          <div class="form-floating mb-3 ml-3">
         
          <input type="text" class="form-control" style={{minWidth:"250px"}} id="floatingIniciador" placeholder="iniciador" 
          {...register('idExpMinisterio', {
         
            pattern:/(?:\d{5}|\(\d{3}\))([-\/\.])\d{7}\1\d{1}/
          })}/>
          <label for="floatingIniciador">Codigo de Expediente Ministerio</label>
          </div>
          
          
          
          
          
          
          <div class="form-floating mb-3" >
      <input type="date" class="form-control" style={{minWidth:"300px"}} id="floatingfechaMinisterio" placeholder="fechaMinisterio" 
      {...register('fechaMinisterio', {
        minLength: 3,
      })}/>
      <label for="floatingfechaMinisterio">Fecha de Exp. Inicial Ministerio</label>
    </div>  {errors.idExpMinisterio?.type === 'pattern' && (
            <span className="aviso_formulario">Ojo, el formato debe ser 12345-1234567-1</span>)}
          </div>
            
        
          <div class="form-floating">
  <textarea class="form-control" style={{height:"100px",resize:"none"}} placeholder="Descripcion de la solicitud" id="floatingDescripcion" 
   {...register('concepto', {
    required: true,
    maxLength:1000 
  })}></textarea>
  <label for="floatingDescripcion">Concepto</label>
</div>    
            
          </div>
                    <div className="contenedor-boton" >

        
<input type="submit" value="Finalizar"  style={{padding:"10px"}}className="btn btn-success" />
<input type="button" value="Cancelar"  className="btn btn-warning" onClick={()=>{props.myID("dale")}} />

</div>
   
        </section>

        </form></div>
     
    </div>
  );
};
export default FormEditaExpte;
