import Cookies from 'universal-cookie';
import { Table } from "reactstrap";
import regresaBienFecha from '../tools/regresaBienFecha';
import devuelveSede from '../tools/devuelveSede';
import './estilos.css'
import devuelveDependenciaConstultada from '../tools/devuelveDependencia.js';
function ModuloDevuelveListaExpedientes (props) {
  const cookies=new Cookies();
  const dependencias=devuelveDependenciaConstultada()
const devuelveDependenciaIniciadora=(valor)=>{

  const cosas=dependencias.filter(x=>x.id==valor)[0].descripcion
  
  return cosas
}
  var cantidad2=1;

    const usuarioPrivilegios = (

      <>
<Table className="table-primary" bordered  size="sm">
<tr style={{backgroundColor:"#cccccc", fontSize:"14px"}}>
      <th className="taa "style={{padding:"8px"}}>
        #
      </th> <th style={{padding:"10px"}}>
        ID Exp
      </th>
      <th style={{padding:"10px"}}>
      Fecha
      </th>
      <th style={{padding:"10px"}}>
      Causante
      </th>
      <th style={{padding:"10px"}}>
        Concepto
      </th>
    
      <th style={{padding:"10px"}}>
      Depen. Inicio
      </th>
      <th style={{padding:"10px"}}>
      Ultimo Movimiento
      </th>
      <th style={{padding:"10px"}}>
     Fecha Ul.Mov
      </th>
      <th>
      Exp Anterior
      </th>
    </tr>
     
      {!props.cosas?"":props.cosas.map((cosas) =><>
 
      <tr className="aa"style={{fontSize:"12px"}}  key={cosas.id}   onClick={()=>props.valor(cosas)}>
   
      <th scope="row" style={{padding:"10px"}}>
     {cantidad2++}
     
      </th>
      <th scope="row">
     {cosas.id}
     
      </th>
      <th style={{padding:"10px"}}>
      {  regresaBienFecha(cosas.createdAt)}
      </th>
      <th style={{padding:"10px"}}>
      {cosas.iniciador}
      </th>
      
      <th style={{padding:"10px"}}>
      { cosas.concepto}
      </th>
     
     
      <th style={{padding:"10px"}}>
      {devuelveDependenciaIniciadora(cosas.dependenciaID)+" ("+ devuelveSede().filter(categoria=>categoria.id==cosas.sedeID)[0].descripcion +")"}
      </th>
      <th style={{padding:"10px"}}>
      {cosas.UltimoMovimiento=="Sin Movimientos"?<><h6 style={{color:"red"}}>{cosas.UltimoMovimiento}</h6></>:<>{cosas.UltimoMovimiento}</>}
        
        
      
      </th>
    <th>
    
    {cosas.FechaUltimoMovimiento}
    </th>
      <th style={{padding:"10px"}}>
     {cosas.idExptAnterior?"Si el Expt "+cosas.idExptAnterior:"-"}
      </th>
    
      </tr>
</>)}
      </Table></>

  );  
  return (
    <div >
      
      {usuarioPrivilegios}
   
    </div>
  );
};
export default ModuloDevuelveListaExpedientes;
