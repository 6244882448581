import { useForm } from 'react-hook-form';
import axios from "axios";
import Cookies from 'universal-cookie';
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario.js';
import devuelveDependenciaConstultada from '../tools/devuelveDependencia.js';
import devuelveCategorias from '../modulos/devuelveCategorias.js';
import convierteExpediente from  '../adaptadores/convierteExpediente.js'
import seteaCokieExpediente from './seteaCokieExpediente.js';
import { useState } from 'react';
import ModalDevuelveListas from './ModalDevuelveListas.js'
import Modal from 'react-modal';

function FormNuevoExpte (props) {
  const cookies=new Cookies();
  const ID=cookies.get('id');
  const [listaDeExpedientes,setListaDeExpedientes]=useState([])
  const [ openModal, setOpenModal]=useState(false)
  const [modalListaExpedienteIsOpen, setModalListaExpedienteIsOpen] = useState(false);
  const [disableBotonFinalizar, setDisableBotonFinalizar]=useState(false)
  const devuelveTodosExpedientes=async(datillo,condicion)=>{
    
    const client = axios.create({
        baseURL:"https://wwwa.isepsantafe.edu.ar/expedientes/"
        });
        try {
        
  
            await client.post("expedientes/").then(f=>setListaDeExpedientes(f.data)).then(openModalListaExpediente())
        }
        catch (error) {}
   
  }
  const fecha = new Date();
const hoy = "Fecha: "+fecha.toLocaleDateString();

  const URI='https://wwwa.isepsantafe.edu.ar/expedientes/'
  
const parametro={}
const usuario=ObtieneDatosCookies()
  const [valorExpt, setValorExpt]=useState('')
  const [apellidoExpte, setapellidoExpte]=useState('')

  const store=async (data)=>{
    try {
      setDisableBotonFinalizar(true)
      console.log(data)
    const resultado = await axios.post(URI+"expedientes/crea",convierteExpediente(data))

    if(resultado){
      await seteaCokieExpediente(resultado.data.Respuesta).then(window.open('/imprime')).then(()=>{props.myID("dale")});
    }
    } catch (error) {
      setDisableBotonFinalizar(false)
    }

   
 
   
   }

  const {register, formState: { errors }, watch, handleSubmit,} = useForm({
  defaultValues: parametro//recupero lo que pasa por parametros
  });
  
const categorias=devuelveCategorias()

  const onSubmit = (data) => {

    store(data)
 
  }

  const fechaMinima = '1995-07-08';
  const fechaMaxima = '2005-07-07';
  const titulo =
    'Generacion de Expedientes ';
  const subTitulo = 'del Instituto de Seguridad Publica';


const tomaIdExpediente=(vañ)=>{
setValorExpt(vañ.id)

setOpenModal(false)
}
const habilitaIdExptAnterior=watch('tieneExptAnterior');
function openModalListaExpediente() {
  setOpenModal(true);
}

function closeModalListaExpediente() {
  setOpenModal(false);
}  function afterOpenModal() {
  // references are now sync'd and can be accessed.
  
}

const customStyles = {
  content: {

  overflowY: "scroll",zIndex:"10"

  },
};  
  return (
    <div>
     <Modal
        isOpen={openModal}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalListaExpediente}
        style={customStyles}
        ariaHideApp={false}
      >
       <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={closeModalListaExpediente}>x </button> <a style={{alignSelf:"center"}} ><strong>Consulta Lista de Expediente: </strong></a>
      
      </div>
      <ModalDevuelveListas  listaExpedientes={listaDeExpedientes.filter(x=>x.idExptPosterior
==null)} myID={(value2)=>tomaIdExpediente(value2)} />

      
         
      </Modal>
  
  <div style={{display:'flex',flexWrap:"wrap",textAlign:"center",gridRowEnd:"-moz-initial",width:"100%",padding:"15px",margin:"10px",backgroundColor:"#005588", color:"#ffeeee"}}>
         
      <h3>Usuario Logueado:{" "+usuario.apellido +", "+usuario.nombre} </h3>
      <h3 style={{marginLeft:"20px"}}>{hoy}</h3>
        <h3 style={{marginLeft:"20px"}}>Division:{devuelveDependenciaConstultada().filter(categoria=>categoria.id==usuario.destino)[0].descripcion} </h3>            </div><div>
          <hr/>





      <form className='form__formulario' onSubmit={handleSubmit(onSubmit)}>
        <section className="datos-personales">
 
 

          <div class='input-group-text' style={{minWidth:"600px"}}>
          <div class="form-floating mb-3 ml-3">

          <input  type="text" style={{minWidth:"200px"}} class="form-control" id="floatingIniciador" placeholder="iniciador" 
          {...register('iniciador', {
          maxlength:"1024",
          required: true,
          minLength: 3,
          })}/>
          <label for="floatingIniciador">Causante</label>
          </div>
          <div class="form-floating mb-3" style={{width:"300px",minWidth:"200px"}}>
          <select class="form-select" id="floatingidCategoria" aria-label="idCategoria"
          {...register('idCategoria', {
           required: true
           })}>
            {categorias.map((cate) =>
            <><option key={cate.id} value={cate.id}>
            {cate.descripcion}
            </option>
            </>
            )}    
    
    
  </select>
  <label for="floatingmotivo">Categoria del Expediente</label>
</div>
<div class="form-floating mb-3 ml-3" style={{width:"300px",minWidth:"200px"}}>
 
          <select class="form-select" id="tieneExptAnterior" aria-label="tieneExptAnterior"
          {...register('tieneExptAnterior', {
           required: true
           })}>
              {usuario.nivel<3? <option value="Sistema ISeP">Sistema ISeP</option>:""}
            <option value="Manual">Manual</option>
            <option selected value="NO">NO</option>
    
    
  </select>
  <label for="tieneExptAnterior" >Tiene Exp ISeP Anterior</label></div>

{habilitaIdExptAnterior==="Manual"&&(  <div class="form-floating mb-3" style={{width:"300px",minWidth:"200px"}}>

<input  type="text" class="form-control" id="idExptAnterior" placeholder="idExptAnterior" 
{...register('idExptAnterior', {

})}/>
<label for="floatingIniciador">Codigo de Exp o Nota Precedente</label>
</div>)}
<div class="form-floating mb-3 ">
  
{habilitaIdExptAnterior==="Sistema ISeP"&&(<> 

<input onClick={devuelveTodosExpedientes} value={valorExpt}type="text" style={{width:"300px"}}class="form-control" id="idExptAnterior" placeholder="idExptAnterior" 
{...register('idExptAnterior', {
required:true
})}/><label for="idExptAnterior">Codigo de Expediente ISeP</label></>)}
{openModal&&(<><div style={{display:"flexbox",zIndex:"99",margin:"-50px",backgroundColor:""}}></div></>)}
          </div>

</div>

                   
          
          <div>
        
          <div class='input-group-text' >
          <div class="form-floating mb-3"style={{width:"120px"}}>
         
         <input type="number" class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('fojasIniciales', {
           minLength: 1,
           required:true
          
         })}/>
         <label for="floatingIniciador">Fojas Iniciales</label>
         </div>
          
         <div class="form-floating mb-3"style={{width:"150px"}} >
      <input type="date" class="form-control" id="floatingfechaInicial" placeholder="fechaInicial" 
      {...register('fechaInicial', {
        minLength: 3,
      })}/>
      <label for="floatingfechaMinisterio">Fecha Anterior?</label>
    </div>
          
    <div class="form-floating mb-3"style={{width:"15px"}}></div>
          
          <div class="form-floating mb-3"style={{width:"300px"}}>
         
          <input type="text" class="form-control" id="floatingIniciador" placeholder="iniciador" 
          {...register('idExpMinisterio', {
            minLength: 3,
            pattern:/(?:\d{5}|\(\d{3}\))([-\/\.])\d{7}\1\d{1}/
          })}/>
          <label for="floatingIniciador">Codigo de Expediente Ministerio?</label>
          </div>
          <div class="form-floating mb-3"style={{width:"235px"}} >
      <input type="date" class="form-control" id="floatingfechaMinisterio" placeholder="fechaMinisterio" 
      {...register('fechaMinisterio', {
        minLength: 3,
      })}/>
      <label for="floatingfechaMinisterio">Fecha de Exp. Inicial Ministerio</label>
    </div>
          </div>
            
          {errors.idExpMinisterio?.type === 'pattern' && (
            <span className="aviso_formulario">Ojo, el formato debe ser 12345-1234567-1</span>)}
         
          <div class="form-floating">
  <textarea class="form-control" style={{height:"100px",resize:"none"}} placeholder="Descripcion de la solicitud" id="floatingDescripcion" 
   {...register('concepto', {
    required: true,
  })}></textarea>
  <label for="floatingDescripcion">Concepto</label>
</div>    



          </div>
          <div>

            
          </div>
                    <div className="contenedor-boton" >

        
<input type="submit" disabled={disableBotonFinalizar} value="Finalizar"  className="btn btn-success" />
<input type="button" value="Cancelar"  className="btn btn-warning" onClick={()=>{props.myID("dale")}} />

</div>
   
        </section>

        </form></div>
     
    </div>
  );
};
export default FormNuevoExpte;
