

const devuelveSede=()=>{
   
    let sedes=[
        {"id":"1","descripcion":"Provincial"},
        {"id":"2","descripcion":"Rosario"},
        {"id":"3","descripcion":"Recreo"},
        {"id":"4","descripcion":"Reconquista"},
        {"id":"5","descripcion":"Rafaela"},
        {"id":"6","descripcion":"Murphy/Melincue"},
       
    
    ]
    
        
        return  sedes
    }
    export default devuelveSede