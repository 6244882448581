import devuelveDependenciaConstultada from '../tools/devuelveDependencia';
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario';
function ModuloDevuelveUsuarios (props) {
  const dependencias=devuelveDependenciaConstultada();
const usuarioLogueado=ObtieneDatosCookies()
var contador=1;
var contador2=1;
const usuarios=props.cosas
usuarios.sort(function (a, b) {
  return a.apellido.localeCompare(b.apellido);
});

    const sidebar = (
< >
<div style={{overflow:"scroll", height:"400px", width:"auto"}}>
<table class="card-body" >

           <tr >  
            <th class="card-title"style={{"font-weight": "800", "scope":"row"}}>#</th> 
            <th class="card-title"style={{"font-weight": "800", "scope":"row"}}>Nombre y apellido </th> 
            <th class="card-text" style={{"font-weight": "800"}}>Destino </th>
            <th style={{"font-weight": "800", "scope":"row"}}>Estado</th> 
            <th style={{"font-weight": "800", "scope":"row"}}>Acción</th> 
           
            </tr>
        { usuarios.map((cosas) =>
        
          <tr key={cosas.id}>
            <th> {contador2++}{"-"}</th>
          <th style={{ width:"350px"}}> {cosas.apellido }{", "} {cosas.nombre}</th>
            
            <th  > 
            {" "}
            {dependencias.filter(categoria=>categoria.id==cosas.destino)[0].descripcion }
            </th> 
            <th  > 
                  {" "}
                  {cosas.estado==1?"Activo":"Inactivo" }
                  </th>
            <th> <button className='btn btn-outline-secondary' onClick={()=>props.myID(cosas)} >editar</button> </th>
         
          </tr>)} 
          </table>
</div>
        
</>
    );
    
    const usuarioPeton = (
      < >
      <div style={{overflowY:"scroll", minHeight:"350px", width:"700px"}}>
      <table class="card-body" >
      
                 <tr >  
                  <th class="card-title"style={{"font-weight": "800", "scope":"row"}}>#</th> 
                  <th class="card-title"style={{"font-weight": "800", "scope":"row"}}>Nombre y apellido </th> 
                  <th class="card-text" style={{"font-weight": "800","scope":"row",width:"200px" }}>Destino </th>

                  <th style={{"font-weight": "800", "scope":"row"}}>Editar</th> 
                 
                  </tr>
              { usuarios.filter(x=>x.destino===usuarioLogueado.destino&&x.estado==1).map((cosas) =>
              
                <tr key={cosas.id}>
                  <th> {contador++}{"-"}</th>
                <th style={{ width:"350px"}}> {cosas.apellido }{", "} {cosas.nombre}
                 </th>
                 
                  <th  > 
                  {" "}
                  {dependencias.filter(categoria=>categoria.id==cosas.destino)[0].descripcion }
                  </th> <th >  {" "}<button className='btn btn-outline-secondary' onClick={()=>props.myID(cosas)} >editar</button> </th>
               
                </tr>)} 
                </table>
      </div>
              
      </>
          );
  return (
    <div>
     
     {usuarioLogueado.destino==7?sidebar:usuarioPeton}
     
    
    </div>
  );
};
export default ModuloDevuelveUsuarios;
