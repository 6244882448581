
import Cookies from 'universal-cookie';
import { Table } from "reactstrap";
import devuelveDependenciaConstultada from '../tools/devuelveDependencia'
import regresaBienFecha from '../tools/regresaBienFecha';
import regresaBienFechaYHora from '../tools/regresaBienFechayDia';
import devuelveSede from '../tools/devuelveSede';
import seteaCokieReciboExpediente from './seteaCokieReciboExpediente'
import devuelvemeElUsuario from '../Expedientes/devuelveUsuario'
function ModuloDevuelveListaMovExpedientes (props) {
  const cookies=new Cookies();
  var cantidad2=1;
    const imprimeRecibo=async(info)=>
    {
      const esteUsuario= await devuelvemeElUsuario(info.usuarioEntradaID)
      if(esteUsuario){
       
        const datosImportantes={

          usuarioEntradaID:info.usuarioEntradaID,
          destinoEntradaID:info.destinoEntradaID,
          destinoSalidaID:info.destinoSalidaID,
          sedeSalidaID:info.sedeSalidaID,
          sedeEntradaID:info.sedeEntradaID,
          expedienteID:info.expedienteID,
          fechaEntrada:info.fechaEntrada,
          comentario:info.comentario,
          idRecibo:"00-"+info.id,
          fojasActuales:info.fojasActuales,
          usuarioRecibe:esteUsuario
        
         }
         seteaCokieReciboExpediente(datosImportantes)
         window.open('/imprimeRecibo')
      }
      
      
}
    const usuarioPrivilegios = (

      <>
<Table className="table-primary" bordered  size="sm">
<tr style={{backgroundColor:"grey"}}>
      <th style={{padding:"10px"}}>
        #
      </th>
      <th style={{padding:"10px"}}>
        Proveniente
      </th>
      <th style={{padding:"2px"}}>
        en Fecha
      </th>
      <th style={{padding:"2px"}}>
      Comentario Salida
      </th>
      <th style={{padding:"2px"}}>
      Comentario de Recipción
      </th>
      <th style={{padding:"2px"}}>
      Fojas
      </th>
      <th style={{padding:"10px"}}>
      Destino Actual
      </th>
      <th>
       desde Fecha
      </th>
      <th style={{padding:"10px"}}>
      Recibo
      </th>
     
    </tr>
     
      {!props.listaMovimientos?"":props.listaMovimientos.map((cosas) =><>
 
      <tr   key={cosas.id} >
   
      <th scope="row">
     {cantidad2++}
     
      </th>
      
      <th style={{padding:"10px"}}>
      {devuelveDependenciaConstultada().filter(categoria=>categoria.id==cosas.destinoSalidaID)[0].descripcion + " ("+devuelveSede().filter(categoria=>categoria.id==cosas.sedeSalidaID)[0].descripcion+")"
      }
      </th>
      <th style={{padding:"10px"}}>
      {regresaBienFechaYHora(cosas.fechaSalida)}
      </th>
      <th style={{padding:"10px"}}>
      {cosas.comentarioSalida}
      </th>
      <th style={{padding:"10px"}}>
      {cosas.comentario}
      </th>
      <th style={{padding:"10px"}}>
      { cosas.fojasActuales}
      </th>
      <th style={{padding:"10px"}}>
      { cosas.destinoEntradaID?devuelveDependenciaConstultada().filter(categoria=>categoria.id==cosas.destinoEntradaID)[0].descripcion + " ("+devuelveSede().filter(categoria=>categoria.id==cosas.sedeEntradaID)[0].descripcion+")":cosas.esArchivo?<><h6 style={{color:"red"}}>{cosas.esArchivo}</h6></>:"En transito"
      }
      </th>
     
      <th style={{padding:"10px"}}>

      {regresaBienFechaYHora(cosas.fechaEntrada)}
      </th>
      <th style={{padding:"10px"}}>
      { cosas.destinoEntradaID&&cosas.fechaEntrada?<button onClick={()=>{imprimeRecibo(cosas)}}>Imprimir</button>:cosas.esArchivo||!cosas.fechaEntrada?"-":"Debe ser Recibido"
      }
      
      </th>
     
      </tr>
</>)}
      </Table></>

  );  
  return (
    <div >
      
      {usuarioPrivilegios}
   
    </div>
  );
};
export default ModuloDevuelveListaMovExpedientes;
