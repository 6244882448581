import ObtieneDatosCookies from "../tools/obtieneDatosCookiesUsuario";

const convierteBaja=(informacionBaja)=>{

  const usuario=ObtieneDatosCookies()
    
    

    return {

      cadeteDni:informacionBaja.cadeteDni,
            idUsuario:usuario.id,
            idSede:usuario.sedeID,
            motivo:informacionBaja.motivo,
            descripcion:informacionBaja.descripcion,
            promocion:informacionBaja.promocion,
            resolucionBaja:informacionBaja.resolucionBaja,
            fechaBaja:informacionBaja.fechaBaja
                       
    }
}
export default convierteBaja;