import ObtieneDatosCookies from "../tools/obtieneDatosCookiesUsuario";

const convierteLTB=(informacion)=>{

  const usuario=ObtieneDatosCookies()
    
    

    return {

      cadeteDni:informacion.cadeteDni,
            idUsuario:usuario.id,
            idSede:usuario.sedeID,
            motivo:informacion.motivo,
            descripcion:informacion.descripcion,
            promocion:informacion.promocion
                       
    }
}
export default convierteLTB;