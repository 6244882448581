import React , { useState, useEffect}from "react";
import {useNavigate,useParams} from 'react-router-dom';
import obtieneDatos  from '../tools/obtieneDatosCookiesUsuario'
import DevuelveCursos from '../certificados/devuelveCursos'
import DevuelveCertificados from '../certificados/devuelveCertificados'
import seteaCertificado from './seteaCokieCertificado'
import axios from 'axios';
import Modal from 'react-modal';
import FormNuevoCertificado from './formNuevoCertificado';
import FormEditoCertificado from './formEditoCertificado';
import CargaMasiva from "./CargaMasiva";

function Certificados () {

    const Usuario=obtieneDatos()
    if(!Usuario.id){
      alert("Acceso no Autorizado")
       window.location.href='./';
       
  }


    const navitate = useNavigate()
    const[cadetes,setCadetes]=useState({}) 
    const[cursosDisponibles,setCursosDisponibles]=useState({}) 
    const[muestroCursos,setMuestroCursos]=useState(false)
    const[nominaCursantes,setNominaCursantes]=useState(false)
    const[muestroNominaCursantesCursos,setMuestroNominaCursantesCursos]=useState(false)
    const[veoCargaMasiva,setVeoCArgaMasiva]=useState(false)
    const[cantidadCadetes,setCantidadCadetes]=useState(0)
    const[cursoSeleccionad,setCursoSeleccionado]=useState({})
    const[condicion,setcondicion]=useState("Regular")
    const [modalNuevoCertificadoIsOpen, setModalNuevoCertificadoIsOpen] = useState(false);
    const [modalEditoCertificadoIsOpen, setModalEditoCertificadoIsOpen] = useState(false);
    const [sedeConsulta,setSedeConsultada]=useState("TODAS")



function afterOpenModal() {
  // references are now sync'd and can be accessed.
  
}
const customStyles = {
  content: {

  overflowY: "scroll",zIndex:"10"

  },
}; 

const crearCertificadoCurso=()=>{
  setModalNuevoCertificadoIsOpen(true)
 
  }

const muestraCursosCertificados=async()=>{
  setMuestroCursos(false)
  const client = axios.create({
      baseURL: "https://wwwa.isepsantafe.edu.ar/expedientes/certificados/consulta/cursosDisponibles/"
      });
      try {
       const cursosConCertificados= await client.post('/')
       if(cursosConCertificados ){

       setCursosDisponibles(cursosConCertificados.data)

  setMuestroCursos(true)
       }
        }
      catch (error) {}
 
}
const muestraDatosCursosCertificados=async(value,estado)=>{
 setCursoSeleccionado(value)
  const client = axios.create({
    baseURL: "https://wwwa.isepsantafe.edu.ar/expedientes/certificados/consulta/cursantesCurso/"
    });
    try {
     const nominaCursantes= await client.post('/'+value.nombreCurso)
     if(nominaCursantes ){
     setNominaCursantes(nominaCursantes.data)
setMuestroNominaCursantesCursos(true)

     }
      }
    catch (error) {}
 
}
const ejemploDeDatos = [
  { label: "Nro Control", key: "id" },
  { label: "DNI", key: "firmante1" },
  { label: "Nombres", key: "cadeteNombre" },
  { label: "Apellido", key: "cadeteApellido" },
  { label: "Sede", key: "cadeteSede" },
 
];
const cambioSede=e=>{
 
    setSedeConsultada(e.target.value);

    sedeConsulta==="TODAS"?setCantidadCadetes(cadetes.length):setCantidadCadetes(cadetes.filter(x=>x.cadeteSede==sedeConsulta).length)
   
    
}
const cambioCondicion=e=>{
 
  setcondicion(e.target.value);

  
}

const editaCurso=()=>{
  setModalEditoCertificadoIsOpen(true)
}
const volver=()=>{
    navitate('/usuarioLogueado')
}
const imprimoCertifcado=(certficadoSeleccionado)=>{
 
  seteaCertificado(certficadoSeleccionado)

  window.open('/imprimeCertificado?ni='+certficadoSeleccionado.dniCertificado+'&curso='+cursoSeleccionad.nombreCurso)

}
function closeModalNuevoCertificado() {
  setModalNuevoCertificadoIsOpen(false);
  muestraCursosCertificados()

}
useEffect(() => {
  muestraCursosCertificados()
  return () => {
    
  };
}, []);
const closeModalEditoCertificado=()=>{
  setModalEditoCertificadoIsOpen(false)
  setMuestroNominaCursantesCursos(false)
  muestraCursosCertificados()
}
const cerreMasivo=()=>{
  setVeoCArgaMasiva(false)
  muestraDatosCursosCertificados(cursoSeleccionad)
  muestraCursosCertificados()
}
    return(
        <>
             <Modal
        isOpen={modalNuevoCertificadoIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalNuevoCertificado}
        style={customStyles}
        ariaHideApp={false}
      >

        
       <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={closeModalNuevoCertificado}>x </button> <a style={{alignSelf:"center"}}><strong>Creacion de Certificado de Curso: </strong></a>
      </div>
      <FormNuevoCertificado myID={(value2)=>closeModalNuevoCertificado()} />
  
      </Modal>
     
         
      <Modal
        isOpen={modalEditoCertificadoIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalEditoCertificado}
        style={customStyles}
        ariaHideApp={false}
      >

        
       <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={closeModalEditoCertificado}>x </button> <a style={{alignSelf:"center"}}><strong>Creacion de Certificado de Curso: </strong></a>
      </div>
      <FormEditoCertificado cursoEditar={cursoSeleccionad} myID={(value2)=>closeModalEditoCertificado()} />
        
         
      </Modal>
     
         <div >
           
          
          </div>
<div style={{textAlign:"center", backgroundColor:"lightgreen", margin:"5px"}}>
           <h1 >Menu de Gestion de Certificaciones</h1> 
           <h3>Usuario logueado:{Usuario.nombre}</h3>
           <button className="btn btn-warning" onClick={volver}>Volver</button>
           </div>
          
           <div style={{ color:"white",textAlign:"center", maxWidth:"400",minHeight:"600",backgroundColor:"#aaa", margin:"5px"}}>
           <button className="btn btn-danger" onClick={crearCertificadoCurso}>Crear Curso</button>
<h1 >Certificaciones Registradas</h1>


{!muestroCursos?"":<><DevuelveCursos cosas={cursosDisponibles} myID={(value)=>muestraDatosCursosCertificados(value,condicion)} /></>}

{!muestroNominaCursantesCursos?"":<>{muestroNominaCursantesCursos?<><button onClick={editaCurso}>EDITAR</button><button onClick={()=>{setVeoCArgaMasiva(true)}} type="button">Agregar Cursantes</button> {veoCargaMasiva?<><CargaMasiva myID={(value)=>cerreMasivo(false)} ListaExistente={nominaCursantes} cursoActual={cursoSeleccionad}/></>:""} </>:""}<DevuelveCertificados cosas={nominaCursantes} myID={(value)=>imprimoCertifcado(value,condicion)} /></>}


           </div>
           
          
        </>
    )
}
export default Certificados