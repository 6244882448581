import Cookies from 'universal-cookie';
const cookies =new Cookies;

const ObtieneDatosCookiesReciboExpediente=()=>{
    
const datos=
{  
   "idRecibo":cookies.get('idRecibo',{path:"/"}),
   "usuarioEntradaID":cookies.get('usuarioEntradaID',{path:"/"}),
  "destinoEntradaID":cookies.get('destinoEntradaID',{path:"/"}),
  "sedeEntradaID":cookies.get('sedeEntradaID',{path:"/"}),
  "sedeSalidaID":cookies.get('sedeSalidaID',{path:"/"}),
  "destinoSalidaID":cookies.get('destinoSalidaID',{path:"/"}),
  "expedienteID":cookies.get('expedienteID',{path:"/"}),
  "fechaEntrada":cookies.get('fechaEntrada',{path:"/"}),
  "comentario":cookies.get('comentario',{path:"/"}),
  "fojasActuales":cookies.get('fojasActuales',{path:"/"}),
  "usuarioRecibe":cookies.get('usuarioRecibe',{path:"/"}),
  

}

   return datos;
      
}
export default ObtieneDatosCookiesReciboExpediente;