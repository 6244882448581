import ObtieneDatosCookies from "../tools/obtieneDatosCookiesUsuario";

const convierteCambioSede=(informacion)=>{

  const usuario=ObtieneDatosCookies()
    
    

    return {
      cadeteDni:informacion.cadeteDni,
      cadeteSede:informacion.cadeteSede,
      idSedeCambiada:informacion.idSedeCambiada,
      idUsuario:usuario.id,
      idSede:usuario.sedeID,
      fechaCambio:informacion.fechaCambio,
      descripcion:informacion.descripcion,
      promocion:informacion.promocion
                       
    }
}
export default convierteCambioSede;