import Cookies from 'universal-cookie';
const cookies =new Cookies;

const ObtieneDatosCookiesExpediente=()=>{
    
const datos=
{ "idUsuarioExpediente":cookies.get('idUsuarioExpediente',{path:"/"}),
  "dependenciaIdExpediente":cookies.get('dependenciaIdExpediente',{path:"/"}),
  "idSedeExpediente":cookies.get('idSedeExpediente',{path:"/"}),
  "idExpediente":cookies.get('idExpediente',{path:"/"}),
  "tieneExptAnterior":cookies.get('tieneExptAnterior',{path:"/"}),
  "idExptAnterior":cookies.get('idExptAnterior',{path:"/"}),
  "idExptPosterior":cookies.get('idExptPosterior',{path:"/"}),
  "iniciador":cookies.get('iniciador',{path:"/"}),
  "idCategoria":cookies.get('idCategoria',{path:"/"}),
  "concepto":cookies.get('concepto',{path:"/"}),
  "idExpMinisterio":cookies.get('idExpMinisterio',{path:"/"}),
  "fechaMinisterio":cookies.get('fechaMinisterio',{path:"/"}),
  "fechaGeneracion":cookies.get('fechaGeneracion',{path:"/"}),
}

   return datos;
      
}
export default ObtieneDatosCookiesExpediente;