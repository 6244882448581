import React,{useState} from "react";

function Convertidor(props){
    const [image,setImage]=useState("")
    const [cargando, setCargando] = useState(true);

    const convertirABase64 =(e)=>{

    var reader=new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload=()=>{
        props.myaID(reader.result)
        setImage(reader.result)
    }
    reader.onerror=error=>{
        console.log("Error: ",error)

    }

        }
     
    return(
    <div>
     <div>

        {image==""||image==null?"":<img width={50} height={50} src={image}/>}
       Firma 1
        <input accept="image/*"
        type="file"
        onChange={convertirABase64}
        ></input>
        </div>
    </div>
)
   
}


export default Convertidor