import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { CSVLink, CSVDownload } from "react-csv";
import { Table } from "reactstrap";
import regresaBienFecha from '../tools/regresaBienFecha';
import devuelvoUsuario from '../tools/obtieneDatosCookiesUsuario.js';
import categorias from '../modulos/devuelveCategorias.js';
import devuelveDependenciaConstultada from '../tools/devuelveDependencia.js';
import { Spinner } from 'reactstrap';
import Modal from 'react-modal';
const Paginacion = (props) => {
  var   cantidadRegistrosMostrados=1
  const devuelveCategorias=categorias()
  const usuario=devuelvoUsuario()
  const [data, setData] = useState([]);
  const [cargando,setCargando]=useState(false)
  const [estado,setEstado]=useState("all")
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const[totalRegistros,setTotalRegistros]=useState(0)
  const[dependenciaConsultada,setDependenciaConsultada]=useState(props.division)
  const[cantidadAConsultar,setCantidadAConsultar]=useState(props.cantidadPasada)
  const [descargar,setDescargar]=useState(false)
  const[dataAll,setDataAll]=useState([])
  const[tiempoEstimado,setTiempoEstimado]=useState(0)
  const devuelveExpediente=(data)=>{
    const Expediente={expediente:data.id}
    return Expediente
        }
  const headers = [
    { label: "Nro Expediente", key: "id" },
    { label: "Causante", key: "causante" },
    { label: "Categoria", key: "categoria" },
    { label: "Concepto", key: "concepto" },
    { label: "Fecha de Creacion", key: "fecha" },
    { label: "Dependencia de Inicio", key: "dependenciaInicio" },
    {label:"Cantidad de Movimientos",key:"cantidadMovimientosRegistrados"},
    {label:"Division de Salida Ultimo Movimiento",key:"divUltMov"},
    {label:"Ultimo Movimiento",key:"ultimoMovimiento"},
    {label:"Fecha Ultimo Movimiento",key:"fechaUltimoMovimiento"}
   
  ];
  function estimarTiempo(tiempo45, datosTotales, datosMuestra = 49) {
    const tiempoEstimado = (tiempo45 * datosTotales) /datosMuestra;
    return tiempoEstimado;
}

// Ejemplo de uso:








  useEffect(() => {
props.seteoSecretaria(dependenciaConsultada)

    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = async (page) => {
    try {
      setCargando(true)
      const inicio = Date.now();  // Marca de tiempo inicial





      props.seteaCantidad(page)
    
      const response = await axios.post(`https://wwwa.isepsantafe.edu.ar/expedientes/expedientes/consultaTotal?destino=${dependenciaConsultada}&&cantidad=${cantidadAConsultar}&&estado=${estado}&&pagina=${currentPage}`);
      for (let index = 0; index < response.data.registros.length; index++) {
        response.data.registros[index].categoria = devuelveCategorias.find(x=>x.id==response.data.registros[index].idCategoria).descripcion;
        
      }
      if(response){
       
        const fin = Date.now();  // Marca de tiempo final
        const tiempoTranscurrido = fin - inicio;  // Diferencia de tiempo en milisegundos
       
        setTiempoEstimado(tiempoTranscurrido) 

      }
      setData(response.data.registros);
      setTotalPages(response.data.totalPaginas);
      setTotalRegistros(response.data.totalRegistros)
     
      props.seteoSecretaria(dependenciaConsultada)
      setCargando(false)
    } catch (error) {
      console.error("Error fetching data", error);
      setCargando(false)
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(prevPage => prevPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(prevPage => prevPage - 1);
    }
  };
  const handlePageChange = (event) => {
   
    const selectedPage = parseInt(event.target.value, 10);

    setCurrentPage(selectedPage);

  };
  
  const verRegistros =async (dependenciaPasada,estadoPasado) => {
    setCargando(true)
    setEstado(estadoPasado)
    setDependenciaConsultada(dependenciaPasada)
  
  
   
  const response = await axios.post(`https://wwwa.isepsantafe.edu.ar/expedientes/expedientes/consultaTotal?destino=${dependenciaPasada}&&cantidad=${cantidadAConsultar}&&estado=${estadoPasado}&&pagina=${currentPage}`);
  setData(response.data.registros);
  setTotalPages(response.data.totalPaginas);
  for (let index = 0; index < response.data.registros.length; index++) {
    response.data.registros[index].categoria = devuelveCategorias.find(x=>x.id==response.data.registros[index].idCategoria).descripcion;
    
  }
 
  setTotalRegistros(response.data.totalRegistros)
  setCargando(false)
 
  props.seteoSecretaria(dependenciaPasada)
  }

  
  function iniciarCuentaRegresiva(segundos) {
    const tiempoRestante = segundos * 1000; // Convertir segundos a milisegundos
    const fin = Date.now() + tiempoRestante; // Marcar el tiempo final

    const intervalo = setInterval(() => {
        const ahora = Date.now();
        const restante = Math.max(0, fin - ahora); // Calcular el tiempo restante

        if (restante <= 0) {
            clearInterval(intervalo); // Detener el intervalo cuando se acabe el tiempo
          
            return;
        }

        const minutos = Math.floor(restante / 60000);
        const segundos = Math.floor((restante % 60000) / 1000);

        // Mostrar el tiempo restante en formato MM:SS
        console.log(`${minutos}m ${segundos}s`);
    }, 1000); // Actualizar cada segundo
}

// Ejemplo de uso:

  const cambiaCantidad=(valor)=>
  {
    const times=estimarTiempo(tiempoEstimado, valor)
const reloj=(times/1000).toFixed(1)
  const pregunta=window.confirm("el tiempo estimado para traer esa cantidad de datos es de: "+reloj+" segundos aproximadamente con la conexión actual. Desea Continuar")
   if(pregunta){
    iniciarCuentaRegresiva(reloj)
    setCantidadAConsultar(valor)
    props.seteaCantidad(valor)
   } 

    
   
  }
  useEffect(() => {
    // Esto se ejecutará después de que `cantidad` sea actualizado
    verRegistros(dependenciaConsultada,estado)
    setCurrentPage(0)
  }, [cantidadAConsultar]); 
  const customStyles = {
    content: {
      display: 'flex', // Establece Flexbox para el contenedor
      flexDirection: 'column', // Alinea los elementos en columna
      justifyContent: 'center', // Centra los elementos verticalmente
      alignItems: 'center', // Centra los elementos horizontalmente
      height: '50%', // Altura del modal, ajusta según sea necesario
      width: '50%', // Ancho del modal, ajusta según sea necesario
      margin: 'auto', // Centra el modal en la página
      background: '#fff', // Color de fondo del modal, ajusta según sea necesario
      borderRadius: '10px', // Bordes redondeados
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Sombra para el modal
      position: 'relative' // Permite un posicionamiento absoluto para elementos internos
    }
  };
  
  const spinnerStyle = {
    fontSize: '3rem', // Ajusta el tamaño del spinner
    color: 'rgba(255, 0, 0, 0.5)', // Color rojo semi transparente, ajusta la opacidad y el color como necesites
    width: '100%', // Ocupa todo el ancho del contenedor para centrar horizontalmente
    textAlign: 'center', // Centra el contenido textualmente si es necesario
    position: 'absolute', // Posicionamiento absoluto dentro del modal
    top: '50%', // Centrado vertical
    left: '50%', // Centrado horizontal
    transform: 'translate(-50%, -50%)' // Ajuste fino para centrar exactamente
  };

  const sortData = async(field, ascending = false) => {
    setCargando(true)
    const sortedData = mezclaTodo(field, ascending); // Ordenar los datos
    await setData([...sortedData]); // Actualizar el estado con una copia de los datos ordenados
    setCargando(false)
  };
  
  const mezclaTodo = (field, ascending = false) => {
    // Hacer una copia del array antes de ordenar para evitar mutar el estado original
    return [...data].sort((a, b) => {
      if (a[field] < b[field]) {
        return ascending ? -1 : 1;
      }
      if (a[field] > b[field]) {
        return ascending ? 1 : -1;
      }
      return 0;
    });
  };
  

  return (
    <>
    
    {cargando?<>
    
    
      <Modal
        isOpen={cargando}
        
        onRequestClose={setCargando}
        style={customStyles}
        ariaHideApp={false}
      ><div style={spinnerStyle}>
    <Spinner color='danger' /> {}
    Accediendo a {cantidadAConsultar} registros, aguarde...
  </div></Modal></>:""}






    <div>
    <CSVLink data={data}  filename={"Expedientes"} className="btn btn-success"separator={";"}
      target="_blank" headers={headers}> Descargar</CSVLink>



      {
        descargar?<CSVDownload data={dataAll}/>:""
      }
            
       <div class="btn-group" role="group" aria-label="Basic example">
       {totalRegistros>49?<>{cantidadAConsultar!=50?<button  className='btn btn-primary' style={{}}onClick={()=>cambiaCantidad(50)}>50</button>:<><button  className='btn btn-secondary' onClick={()=>cambiaCantidad(50)}>50</button></>}</>:""} 
       {totalRegistros>99?<>{cantidadAConsultar!=100?<button  className='btn btn-primary' style={{}}onClick={()=>cambiaCantidad(100)}>100</button>:<><button  className='btn btn-secondary' onClick={()=>cambiaCantidad(100)}>100</button></>}</>:""} 
       {totalRegistros>299?<>{cantidadAConsultar!=300?<button  className='btn btn-primary' style={{}}onClick={()=>cambiaCantidad(300)}>300</button>:<><button  className='btn btn-secondary' onClick={()=>cambiaCantidad(300)}>300</button></>}</>:""} 
       {totalRegistros>499?<>{cantidadAConsultar!=500?<button  className='btn btn-primary' style={{}}onClick={()=>cambiaCantidad(500)}>500</button>:<><button  className='btn btn-secondary' onClick={()=>cambiaCantidad(500)}>500</button></>}</>:""} 
       {totalRegistros>999?<>{cantidadAConsultar!=1000?<button  className='btn btn-primary' style={{}}onClick={()=>cambiaCantidad(1000)}>1000</button>:<><button  className='btn btn-secondary' onClick={()=>cambiaCantidad(1000)}>1000</button></>}</>:""} 
       {totalRegistros>1001?<>{cantidadAConsultar!=totalRegistros?<button  className='btn btn-primary' style={{}}onClick={()=>cambiaCantidad(totalRegistros)}>{totalRegistros}</button>:<><button  className='btn btn-secondary' onClick={()=>cambiaCantidad(totalRegistros)}>{totalRegistros}</button></>}</>:""}
 
</div>
      
      <div> 
         <div class="btn-group" role="group" aria-label="Basic example">
        <button onClick={handlePrevPage} className='btn btn-secondary' disabled={currentPage === 0}>Anterior</button>
       
        <button onClick={handleNextPage}  className='btn btn-secondary'disabled={currentPage === totalPages - 1}>Siguiente</button>
        </div>
        {totalPages > 0 && (
  <select name="" id=""onChange={handlePageChange}>
    {Array.from({ length: totalPages }, (_, index) => (
      <option key={index} value={index} selected={index === currentPage}>
        {index + 1}
      </option>
    ))}
  </select>
  
)}

{usuario.destino=="7"||usuario.identificacion=="594903"||usuario.destino=="1"?<select value={dependenciaConsultada} id='selectorEscuela' onChange={(e)=>verRegistros(e.target.value,estado)} style={{padding:"2px", marginLeft:"5px"}}>
<option value="all">Todos</option>

{devuelveDependenciaConstultada().map((cate) =>
     <><option key={cate.id} value={cate.id}>
     {cate.descripcion}
     </option></>)}
 </select>:""}




 {usuario.destino!=="7"?<select value={estado} id='selectroEstado' onChange={(e)=>verRegistros(dependenciaConsultada,e.target.value)} style={{padding:"2px", marginLeft:"5px"}}>
<option value="all">Todos</option>
<option value="En el ISeP">En el ISeP</option>
<option value="Fuera del ISeP">Fuera del ISeP</option>
<option value="Archivo">Archivo</option>
 </select>:<select value={estado} id='selectroEstado' onChange={(e)=>verRegistros(dependenciaConsultada,e.target.value)} style={{padding:"2px", marginLeft:"5px"}}>
 <option value="all">Todos </option>
<option value="En el ISeP">En el ISeP </option>
<option value="Fuera del ISeP">Fuera del ISeP </option>
<option value="Archivo">Archivo </option>
<option value="Anulado">Anulado </option>
 </select>}
  <h7>Página {currentPage + 1} de {totalPages}. </h7>
      </div>
    
      <>
<Table className="table-primary" bordered  size="sm">
<tr style={{backgroundColor:"#cccccc", fontSize:"14px"}}>
<th style={{ padding: "10px" }} >
            #
          </th>
 <th style={{ padding: "8px" }} onClick={() => sortData("id")}>
            ID Exp
          </th>
          <th style={{ padding: "10px" }} onClick={() => sortData("fecha")}>
            Fecha
          </th>
          <th style={{ padding: "1px" }} onClick={() => sortData("causante")}>
            Causante
          </th>
          <th style={{ marginLeft: "-100px" }} onClick={() => sortData("categoria")}>
            Categoria
          </th>
          <th style={{ padding: "10px" }} onClick={() => sortData("concepto")}>
            Concepto
          </th>
          <th style={{ padding: "10px" }} onClick={() => sortData("dependenciaInicio")}>
            Depen. Inicio
          </th>
          <th style={{ padding: "10px" }} onClick={() => sortData("cantidadMovimientosRegistrados")}>
            Total Mov.
          </th>
          <th style={{ padding: "10px" }} onClick={() => sortData("divUltMov")}>
            Div Ult Mov
          </th>
          <th style={{ padding: "10px" }} onClick={() => sortData("ultimoMovimiento")}>
            Ultimo Movimiento
          </th>
          <th style={{ padding: "10px" }} onClick={() => sortData("fechaUltimoMovimiento")}>
            Fecha Ul.Mov
          </th>
          <th onClick={() => sortData("expAnterior")}>Exp Ant</th>
        </tr>

      {!data?"":data.map((cosas) =><>
  
      <tr className="aa"style={{fontSize:"12px"}}  key={cosas.id}   onClick={()=>props.myID(devuelveExpediente(cosas))}>
   <th> {cantidadRegistrosMostrados++}</th>

      <th scope="row">
     {cosas.id}
     
      </th>
      <th style={{padding:"10px"}}>
      {regresaBienFecha(cosas.fecha)}
      </th>
      <th style={{padding:"1px"}}>
      {cosas.causante}
      </th>
      <th style={{marginLeft:"-100px"}}>
        {cosas.categoria}
      </th>
      <th style={{padding:"10px"}}>
      { cosas.concepto}
      </th>
     
     
      <th style={{padding:"10px"}}>
      { cosas.dependenciaInicio}
      </th>
      <th style={{padding:"10px"}}>
    
    {cosas.cantidadMovimientosRegistrados}
    </th>
      <th style={{padding:"10px"}}  >
    
    {cosas.divUltMov}
    </th>
      
       
      { cosas.ultimoMovimiento=="no tiene"?<><th style={{padding:"10px",color:"red",fontSize:"15px"}}>SIN MOVIMIENTOS</th></>:cosas.ultimoMovimiento=="ARCHIVO"?<th style={{padding:"10px",color:"#16a085",fontSize:"15px"}}>{cosas.ultimoMovimiento}</th>:<th>{cosas.ultimoMovimiento}</th>}

 
      
    <th>
    
    {cosas.fechaUltimoMovimiento}
    </th>
      <th style={{padding:"10px"}}>
     {cosas.expAnterior?"Si el Expt "+cosas.expAnterior:"-"}
      </th>
    
      </tr>
</>)}
      </Table></>
      Registros mostrados {cantidadRegistrosMostrados-1}
      
    </div></>
  );
};

export default Paginacion;
