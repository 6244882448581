import Cookies from 'universal-cookie';
import{ useState }from "react";
import { Table } from "reactstrap";
import regresaBienFechaYDia from '../tools/regresaBienFechayDia';
import regresaBienFecha from '../tools/regresaBienFecha';
import Modal from 'react-modal';
import axios from 'axios';
import DevuelveDatosDocente from './DevuelveDatosDocentes'
import Swal from 'sweetalert2'
import obtieneDatos  from '../tools/obtieneDatosCookiesUsuario'
function ModuloDevuelveDocentes (props) {
  const Usuario=obtieneDatos();
  const cookies=new Cookies();
const Docentes= props.Docentes

 var contador=1
const[datosDocente,setDatosDocente]=useState(null)
const[muestroDatos,setMuestroDatos]=useState(false)
const[inasistenciasDocente,setInasistencias]=useState(null)

 const devuelveDocente=async(docente)=>{
  const client = axios.create({

    baseURL:"https://wwwa.isepsantafe.edu.ar/expedientes/docentes/devuelveDocente"
    });
    try {
     
      const Docente=await client.post('/'+docente.docenteDni)
      if(Docente){

const DatosMaterias=await Docente.data.DatosMaterias
const HorasAsignadas=await Docente.data.HorasAsignadas

setInasistencias(Docente.data.Inasistencias)
const infoDocente=[]

for (var i = 0; i < HorasAsignadas.length; i++) {

infoDocente.push({"Division":HorasAsignadas[i].division, "Materia":DatosMaterias[i][0].nombreMateria,"curso":DatosMaterias[i][0].curso,"materiaAsignadaId":HorasAsignadas[i].id, "Escuela":DatosMaterias[i][0].escuela,"Horas":HorasAsignadas[i].horasAsignadas,"docente":docente.docenteApellido+", "+docente.docenteNombres,"docenteDni":docente.docenteDni,"sedeCursada":HorasAsignadas[i].sedeCursada,"esRentada":HorasAsignadas[i].esRentada})
      
}
if(DatosMaterias.length>0){
setDatosDocente(null)
  setDatosDocente(infoDocente)
  setMuestroDatos(true)
  setmodalInformeDocenteIsOpen(true)
  
 

}
    }
    }catch{}
 }


 const creaInasistencia=async(data)=>{
  
  const fechaInasistencia=new Date()

 var informacion={
   "docente":data.docente,
   "docenteDni":data.docenteDni,
   "escuela":data.Escuela,
   "curso":data.curso,
   "materiaAsignadaId":data.materiaAsignadaId,
  "materia":data.Materia,
  "division":data.Division,
  "estadoInasistencia":"Activa",
  "sedeCursada":data.sedeCursada,
   "usuarioID":Usuario.id,
   "sedeID":Usuario.sedeID,
   "dependenciaID":Usuario.destino
 }
 
     const client = axios.create({
   
       baseURL:"https://wwwa.isepsantafe.edu.ar/expedientes/docentes/creaInasistencia"
       });
       try {
         let motivo=""
         Swal.fire({
          html: `<label>Motivo</label>
          <select
            
            class="swal2-input"
            id="motivo-value" >
            <option value="Cuestiones Medicas">Cuestiones Medicas</option>
             <option value="Razones de Servicio">Razones de Servicio</option>
             <option value="Licencia">Licencia</option>
             <option value="Asistencia Familiar">Asistencia Familiar</option>
             <option value="No informa">No informa</option>
            </select>
            `,
           title: "Indique si la inasistencia es de este día y el motivo",
           icon: "question",content: "date", closeOnClickOutside: false, 
           showDenyButton: true,
   showCancelButton: true,
   confirmButtonText: "No, es de otro dia",
   denyButtonText: `Si, es de Hoy`,
   cancelButtonText:`Cancelar`,
  
             
         }).then((result) => {
          
           if (result.isConfirmed) {
           motivo=document.getElementById('motivo-value').value
             Swal.fire({
               title: "Indique en que fecha fue",
               icon: "question",
               showCancelButton: true,
                cancelButtonText:`Cancelar`,
               closeOnClickOutside: false,
               html: `
     <input
       type="date"
       value="2024-03-01"
       class="swal2-input"
       id="range-value" >
       `,
                   
                
                 
             }).then((result) => {
              if (result.isConfirmed) {continuo(document.getElementById('range-value').value)}})
           }else if(!result.isDismissed){
            motivo=document.getElementById('motivo-value').value
            continuo(fechaInasistencia)
           }
         });
 
 
 const continuo=async(fecha)=>{
  
  
   informacion.fechaInasistencia=fecha
   informacion.motivo=motivo
   console.log(informacion)
   const Inasistencia=await client.post('/',informacion)
   if(Inasistencia.data.Mensaje){
     Swal.fire("error",Inasistencia.data.Mensaje,"error").then(()=>closeModalInfoDocente())
   }else{
     Swal.fire({
       text:Inasistencia.data.docente,
       title:"Inasistencia Creada Correctamente",
   buttons:"Aceptar",
   timer: "4000",
       icon:"success"}).then(()=>closeModalInfoDocente())
   }
 }
 
 
        
         
       }catch{
 
       }
 }





const customStyles = {
  content: {
    top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  width:"auto",
  height:"auto"

  },
};
const [modalInfoDocenteIsOpen,setmodalInformeDocenteIsOpen]=useState(false)
const closeModalInfoDocente=()=>{
setmodalInformeDocenteIsOpen(false)

}
  const usuario= (

 <> 
 
 {muestroDatos?<>
  <Modal
        isOpen={modalInfoDocenteIsOpen}
         onRequestClose={closeModalInfoDocente}
        style={customStyles}
        ariaHideApp={false}
      >

<div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={closeModalInfoDocente}>x </button> <a style={{alignSelf:"center"}}><strong>Informacion del Docente: </strong></a>



       
</div>
<div>

  {  muestroDatos&&datosDocente?   <DevuelveDatosDocente Datos={datosDocente} Inasistencias={inasistenciasDocente} myID={(value)=>creaInasistencia(value)} cierro={()=>closeModalInfoDocente}/>:""}
</div>

      </Modal>
 
</>:""}
 <Table className="table-primary" bordered  size="sm">
 <tr style={{fontSize:"15px"}}>
       <th >
         #
       </th>
       <th style={{width:"150px"}} onClick={()=>{console.log(Docentes.filter(x=>x.id>="4"&&x.id<"10"))}}>
       Apellido
       </th>
       <th style={{width:"200px"}}>
       Nombre
       </th>
       <th>
       DNI
       </th>
       <th>
       Horas Totales
       </th>
    
     </tr>

    
    

 {!Docentes?"":Docentes.map((cosas) =><>

  <tr className="aa"style={{fontSize:"12px"}} onClick={()=>{devuelveDocente(cosas)}}  key={cosas.id}  >

  <th scope="row" >
  
 {contador++}

  </th>
  <th>
  {cosas.docenteApellido}
  </th>  
  <th>
  {cosas.docenteNombres}
  </th>  
  
  <th>
  {cosas.docenteDni}
  </th>  
  <th style={{textAlign:"center"}}>
  {cosas.HorasCatedrasTotales}
  
  </th>
 
  </tr>
</>)}

   
      
       </Table></>
 )
    
  return (
    <div>


     {usuario}
   
    </div>
  );
};
export default ModuloDevuelveDocentes;
