import ObtieneDatosCookies from "../tools/obtieneDatosCookiesUsuario";

const convierteActualizaMovimiento=(info)=>{
  const usuario=ObtieneDatosCookies()
    
      const fecha = new Date();

    return {
            usuarioEntradaID:usuario.id,
            destinoEntradaID:usuario.destino,
            sedeEntradaID:usuario.sedeID,
            fechaEntrada:fecha,
            comentario:info
                       
    }
}
export default convierteActualizaMovimiento;