import Cookies from 'universal-cookie';
import{ useState }from "react";
import { Table } from "reactstrap";
import regresaBienFechaYDia from '../tools/regresaBienFechayDia';
import regresaBienFecha from '../tools/regresaBienFecha';
import Modal from 'react-modal';
import axios from 'axios';
import obtieneDatos  from '../tools/obtieneDatosCookiesUsuario'
import Swal from 'sweetalert2'
import cambiaDocente from './cambiaDocente'
import anulaAsignacionDocente from './anulaAsignacionDocente'
function ModuloDevuelveMaterias (props) {
  const cookies=new Cookies();
const Materias= props.cosas.Materia

const Usuario=obtieneDatos();

  const MateriasAsignadas=props.cosas.HorasMaterias
 const [infoMateriaConDocente,setInfoMateria]=useState("")
 const [muestroMateriaConDocente,setMuestroInfoMateria]=useState(false)
 const[modalInformeDocenteIsOpen,setmodalInformeDocenteIsOpen]=useState(false)
 const[datas,setDatas]=useState([])
  const infoMateria=async(idMateria)=>{
   
 
      const client = axios.create({
    
        baseURL:"https://wwwa.isepsantafe.edu.ar/expedientes/materias/devuelveMateria"
        });
        try {
         
          const Materias=await client.post('/'+idMateria,)
          if(Materias){
           
            setDatas(Materias.data)
            setInfoMateria(Materias.data.MateriasConDocentes)
            setMuestroInfoMateria(true)
            setmodalInformeDocenteIsOpen(true)
          }
        }catch{

        }
  }
  const creaInasistencia=async(data)=>{
  
 const fechaInasistencia=new Date()
 
var informacion={
  "docente":data.docente,
  "docenteDni":data.docenteDni,
  "escuela":datas.Materia[0].escuela,
  "curso":datas.Materia[0].curso,
  "materiaAsignadaId":data.materiaAsignadaId,
 "materia":data.materia,
 "division":data.division,
 "estadoInasistencia":"Activa",
 "sedeCursada":datas.HorasMaterias.find(x=>x.id==data.materiaAsignadaId).sedeCursada,
  "usuarioID":Usuario.id,
  "sedeID":Usuario.sedeID,
  "dependenciaID":Usuario.destino
}
console.log(informacion.materiaAsignadaId)
const alumnos=await axios.post('https://wwwa.isepsantafe.edu.ar/expedientes/calificaciones/devuelveAlumnosCurso?aulaID='+informacion.materiaAsignadaId).then(f=>console.log(f.data))
    const client = axios.create({
  
      baseURL:"https://wwwa.isepsantafe.edu.ar/expedientes/docentes/creaInasistencia"
      });
      try {
         let motivo=""
        Swal.fire({ 
          html: `<label>Motivo</label>
          <select
            
            class="swal2-input"
            id="motivo-value" >
            <option value="Cuestiones Medicas">Cuestiones Medicas</option>
             <option value="Razones de Servicio">Razones de Servicio</option>
             <option value="Licencia">Licencia</option>
             <option value="Asistencia Familiar">Asistencia Familiar</option>
             <option value="No informa">No informa</option>
            </select>
            `,
          title: "La inasistencia es de este día?",
          icon: "question",content: "date", closeOnClickOutside: false, 
          showDenyButton: true,
  showCancelButton: true,
  
  confirmButtonText: "No, es de otro dia",
  denyButtonText: `Si, es de Hoy`,
  cancelButtonText:`Cancelar`
            
        }).then((result) => {
          if (result.isConfirmed) {
            motivo=document.getElementById('motivo-value').value
            Swal.fire({
              title: "Indique en que fecha fue",
              icon: "question",
              showCancelButton: true,
              cancelButtonText:`Cancelar`,
              closeOnClickOutside: false,
              html: `
    <input
      type="date"
      value="2024-03-01"
      class="swal2-input"
      id="range-value" >
      `,
                  
               
                
            }).then((result) => {
              if (result.isConfirmed) {continuo(document.getElementById('range-value').value)}})
           }else if(!result.isDismissed){
            motivo=document.getElementById('motivo-value').value
            continuo(fechaInasistencia)
          }
        });


const continuo=async(fecha)=>{

  informacion.fechaInasistencia=fecha
  informacion.motivo=motivo
  const Inasistencia=await client.post('/',informacion)
  if(Inasistencia.data.Mensaje){
    Swal.fire("error",Inasistencia.data.Mensaje,"error")
  }else{
    Swal.fire({
      text:Inasistencia.data.docente,
      title:"Inasistencia Creada Correctamente",
  buttons:"Aceptar",
  timer: "4000",
      icon:"success"})
  }

}


       
        
      }catch{

      }
}
  var cantidad1=0;
  var contador=1;
  function devuelveDivisionesLibres(Materia,numerador){
    const valor=numerador-"1";
    var divisionesOcupadas=MateriasAsignadas[valor].length;
   
   
    return Materia.cantDivisiones-divisionesOcupadas
  }
  const[modalEditaMateriaIsOpen,setModalMateriaIsOpen]=useState(false);
  function closeModalEditaMateria() {
    setModalMateriaIsOpen(false);
  
  }

  function openModalMaterias() {
    setModalMateriaIsOpen(true);
  }
    const customStyles = {
  content: {
    top: '50%',
  left: '50%',
  right: 'auto',
 
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  width:"auto",
  height:"500px"

  },
};


const closeModalInformeDocentes=()=>{
  setmodalInformeDocenteIsOpen(false)
}
const quitaAsignacion=async(info)=>
{
anulaAsignacionDocente(info)
}
const cambiaElDocente=async(info)=>{
  cambiaDocente(info)
  setmodalInformeDocenteIsOpen(false)
}
  const usuario= (

 
 <Table className="table-primary" bordered  size="sm">
 <tr style={{fontSize:"15px"}}>
       <th>
         #
       </th>
       <th style={{width:"200px"}}>
        Materia
       </th>
       <th style={{width:"50px"}}>
       Div Libres
       </th>
       <th>
       Bloque
       </th>
       <th>
       Inicia
       </th>
       <th>
       Finaliza
       </th>
       <th>
       Curso
       </th>
       <th>
       Asigna
       </th>
     </tr>
      
       {!Materias?"":Materias.map((cosas) =><>
  
       <tr  onClick={()=>{infoMateria(cosas.id)}} key={cosas.id} style={{fontSize:"12px"}}  >
    
       <th scope="row">
       <a style={{display:"none"}}>{cantidad1++}</a>
      {contador++}
     
       </th>
       
       <th>
       {cosas.nombreMateria}
       </th>  <th>
       {devuelveDivisionesLibres(cosas,cantidad1) }
       
       </th>
       <th>
       { cosas.bloqueHorario + " Hs."}
       </th>
       
       <th>
       {  regresaBienFecha(cosas.fechaInicio)}
       </th>
       <th>
       { regresaBienFecha(cosas.fechaFinalizacion) }
       </th>
       <th>
       {  cosas.curso}
   
       </th>
      <th>{devuelveDivisionesLibres(cosas,cantidad1)>0?<button style={{backgroundColor:"green"}} onClick={()=>props.myID(cosas)}>X</button>:
      <button disabled  style={{backgroundColor:"red"}}onClick={()=>props.myID(cosas)}>X</button> }
     
      </th>
       </tr>
 </>)}
       </Table>
 )
    
  return (
    <>
    <Modal
    isOpen={modalInformeDocenteIsOpen}
    onRequestClose={closeModalInformeDocentes}
    style={customStyles}
    >
   <div style={{"width":"600px"}} > <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={closeModalInformeDocentes}>x </button> <a style={{alignSelf:"center"}}><strong>Informe de: </strong></a>

   
   {muestroMateriaConDocente&&infoMateriaConDocente.length>0?<><h5> {infoMateriaConDocente.length>0?infoMateriaConDocente[0].materia:""}</h5>
<Table className="table-primary" bordered  size="sm">
<tr style={{fontSize:"15px"}}>
       <th style={{width:"50px"}}>
       Div
       </th>
       <th style={{width:"200px"}}>
       Docente
       </th>
       <th style={{width:"150px"}}>
       DNI
       </th>
       <th style={{width:"300px"}}>
      
       </th>
       
     </tr>
{infoMateriaConDocente?infoMateriaConDocente.map((info) =><>
  
       <tr key={info.id} style={{fontSize:"12px"}}  >
    
       
       <th>
  
       {info.division}
       </th>
       <th>
       {info.docente}
       </th>  
       <th>
       {info.docenteDni}
       </th> 
       <th className="btn btn-success"style={{fontSize:"10px",justifySelf:"center"}}onClick={()=>{creaInasistencia(info)}} >Crear Inasistencia</th>
       

       {Usuario.destino=="7"?<th className="btn btn-warning"style={{fontSize:"10px",justifySelf:"center"}}onClick={()=>{cambiaElDocente(info)}} >Cambiar Docente</th>:""}
       {Usuario.destino=="7"?<th className="btn btn-danger"style={{fontSize:"10px",justifySelf:"center"}}onClick={()=>{quitaAsignacion(info)}} >X</th>:""}
       </tr>
      
 </>):""}</Table>
</>:""}
   
  </div>
  </Modal>
    <div>


     {usuario}
   
    </div></>
  );
};
export default ModuloDevuelveMaterias;
