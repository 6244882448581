
import { Table } from "reactstrap";
import devuelveDependenciaConstultada from '../tools/devuelveDependencia'
import regresaBienFecha from '../tools/regresaBienFecha';
import devuelveSede from '../tools/devuelveSede';
function ModuloDevuelveListaExpedientesDependencia (props) {


  var cantidad2=1;
    
    const usuarioPrivilegios = (

      <>
<Table className="table-primary" bordered  size="sm">
<tr style={{backgroundColor:"#cccccc", fontSize:"10px"}}>
      <th style={{padding:"8px"}}>
        #
      </th> <th style={{padding:"10px"}}>
        ID Exp
      </th>
      <th style={{padding:"10px"}}>
      Fecha
      </th>
      <th style={{padding:"10px"}}>
        Iniciador
      </th>
      <th style={{padding:"10px"}}>
        Concepto
      </th>
    
      <th style={{padding:"10px"}}>
      Depen. Inicio
      </th>
      <th style={{padding:"10px"}}>
      Provino desde
      </th>
      <th style={{padding:"10px"}}>
      Desde Fecha
      </th>

    </tr>
     
      {!props.cosas?"":props.cosas.map((cosas) =><>
 
      <tr style={{fontSize:"12px"}}  key={cosas.id}   onClick={()=>props.myID(cosas)}>
   
      <th scope="row" style={{padding:"10px"}}>
     {cantidad2++}
     
      </th>
      <th scope="row">
     {cosas[0].id}
     
      </th>
      <th style={{padding:"10px"}}>
      {  regresaBienFecha(cosas[0].createdAt)}
      </th>
      <th style={{padding:"10px"}}>
      {cosas[0].iniciador}
      </th>
      
      <th style={{padding:"10px"}}>
      { cosas[0].concepto}
      </th>
     
     
      <th style={{padding:"10px"}}>
      { devuelveDependenciaConstultada().filter(categoria=>categoria.id==cosas[0].dependenciaID)[0].descripcion +" ("+ devuelveSede().filter(categoria=>categoria.id==cosas[0].sedeID)[0].descripcion +")"}
      </th>
      <th style={{padding:"10px"}}>
      { cosas.ProvinoDesdeDestino!="Z"?<>{ devuelveDependenciaConstultada().filter(categoria=>categoria.id==cosas.ProvinoDesdeDestino)[0].descripcion +" ("+ devuelveSede().filter(categoria=>categoria.id==cosas.ProvinoDesdeSede)[0].descripcion +")"}</>:<h6 style={{color:"red"}}>Salida en transito desde este destino</h6>}
      </th>
      <th style={{padding:"10px"}}>
      {cosas.FechaUltimoMovimiento}
      </th>
     
      </tr>
</>)}
      </Table></>

  );  
  return (
    <div >
      
      {usuarioPrivilegios}
   
    </div>
  );
};
export default ModuloDevuelveListaExpedientesDependencia;
