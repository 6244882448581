
import regresaBienFecha from '../tools/regresaBienFecha';
import { Table } from "reactstrap";
import { useState } from 'react';

function ModuloDevuelveInasistenciasDocentes (props) {

 

const [DatosDocente,setDatosDocente]=useState(props.Docentes)


  return (
   <> <div>

<Table className="table-primary" bordered  size="sm">
 

 <tr style={{fontSize:"15px"}}>
        <th style={{width:"50px"}} onClick={()=>props.myID("id")}>
          ctrol
        </th>
        <th style={{width:"110px"}} onClick={()=>props.myID("docente")} >
         Docente
        </th>
        <th style={{width:"80px"}} onClick={()=>props.myID("motivo")}>
         Motivo
        </th>
        <th style={{width:"80px"}} onClick={()=>props.myID("curso")}>
         Curso
        </th>
        <th >
        Escuela
        </th>
        <th style={{width:"200px"}} onClick={()=>props.myID("materia")}>
       Materia
        </th>
      
        <th style={{width:"200px"}} onClick={()=>props.myID("division")}>
        Div
        </th>
        <th style={{width:"200px"}} onClick={()=>props.myID("sede")}>
       Sede
        </th>
        <th style={{width:"200px"}} onClick={()=>props.myID("fechaInasistencia")}>
        Fecha
        </th>
        <th style={{width:"300px"}} onClick={()=>props.myID("devolvioInasistencia")}>
        Devolvio
        </th>
      </tr>
  {!DatosDocente?"":DatosDocente.map((cosas) =><>
 
   <tr  key={cosas.id} className="aa"style={{fontSize:"12px"}} >
 
   <th scope="row" >
   {cosas.id}
 
 
   </th>
 
   <th>
   {cosas.docente}
   </th>  
   <th>
   {cosas.motivo}
   </th> 
   <th>
   {cosas.curso}
   </th> 
   <th>
   {cosas.escuela}
   </th> 
   <th>
   {cosas.materia}
   </th>  
   <th>
   {cosas.division}
   </th>
   <th>
   {cosas.sedeCursada}
   </th>  
    
   <th style={{textAlign:"center"}}>
   {regresaBienFecha(cosas.fechaInasistencia)}
   
   </th>
   <th style={{textAlign:"center"}} >
   {cosas.devolvioInasistencia==null?<button className='btn btn-success' onClick={()=>props.creaDevolucion(cosas)}> Devuelve </button>:<><h6 style={{borderRadius:"15px",backgroundColor:"#16a085", marginTop:"4px", color:"#f2f4f4"}} onClick={()=>props.anula(cosas.id)} >devolvió el {regresaBienFecha(cosas.devolvioInasistencia)} segun {cosas.folioInciso}</h6></>}
   
  
   
   </th>
   </tr>
 </>)}
 
    
       
        </Table>

   
    </div></>
  );
};
export default ModuloDevuelveInasistenciasDocentes;
