import { useForm } from 'react-hook-form';
import axios from "axios";
import convierte from '../adaptadores/convierte.js'
import {useNavigate} from 'react-router-dom';
import Cookies from 'universal-cookie';
import seteaCokie from '../tools/seteaCokie.js';
import { useState } from 'react';
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario.js'
import asignaPass from '../tools/asignaPass.js'
import devuelveSedeConsultada from '../tools/devuelveSede.js';
import devuelveDependenciaConstultada from '../tools/devuelveDependencia.js';


function ModuloCreaUsuario (props) {
const cookies=new Cookies();
const navitate = useNavigate();
const destinos=devuelveDependenciaConstultada()
const sedes=devuelveSedeConsultada()
const Usuario=ObtieneDatosCookies()
const [verForm,setVerForm]=useState(false)
  const URI='https://wwwa.isepsantafe.edu.ar/expedientes/usuarios/crea/'
 

  
  const creaUsuario=async (data)=>{
    const nuevoPass=await asignaPass("Alem+2050")
const pass=nuevoPass.pass
data.apellido=data.apellido.toUpperCase()
  data.pass=pass;
  data.blanqueoContrasena=1;
    const resultado=await axios.post(URI,data)
if(resultado.data.message=="Registro creado correctamente!!"){
  alert("Usuario Creado Correctamente la pass es: Alem+2050")
  props.myID("return")
}else{
  alert("Algo fallo al crear el usuario, por favor contacte al soporte Tecnico")
}
   }
const {register, formState: { errors }, watch, handleSubmit,} = useForm({
defaultValues: []//recupero lo que pasa por parametros
});
  


  


  return (
    <div class="">
      <div className="titulo">
      
        <h3>NUEVO USUARIO SISTEMA DE GESTION</h3>
      
      </div>
    
      <div class="row">
      <form class="" onSubmit={handleSubmit(creaUsuario)}>
      
        <section >
    
    <div className="grupo_form">
          {errors.apellido?.type === 'required' && (
            <span className="aviso-campos-loguin">*</span>
          )}
          

          <input
            type="text"
            placeholder="Apellido"
            className="input-group-text"
            id="apellido"
            {...register('apellido', {
              required: true,
              minLength: 3,
              maxLength:32
            })}
          />
           
            <span className="form__line"></span>
    </div>
    <div className="grupo_form">
    {errors.nombre?.type === 'required' && (
            <span className="aviso-campos-loguin">*</span>
          )}
          

          <input
            type="text"
            placeholder="Nombre"
            className="input-group-text"
            id="nombre"
            {...register('nombre', {
              required: true,
              minLength: 3,
              maxLength:32
            })}
          />

    </div>
    
    <div className="grupo_form">
    {errors.identificacion?.type === 'required' && (
            <span className="aviso-campos-loguin">*</span>
          )}
          
          {errors.identificacion?.type === 'minLength' && (
              <span className="aviso_formulario">
               6 u 8 numeros
              </span>
            )} {Usuario.nivel=="1"?<>
          <input
            type="text"
            placeholder="Nro de Identificacion"
            className="input-group-text"
            id="identificacion"
            {...register('identificacion', {
              required: true,
              minLength: 3,
              maxLength:16
            })}
          /></>:""}

            <span className="form__line"></span>
    </div>
    {Usuario.nivel=="1"?<>
    <div class="input-group mb-3">
  <div class="input-group-prepend">
    <label class="input-group-text" for="inputGroupSelect01">Sede de Destino</label>
  </div>
  <select
            className="custom-select" id='sedeID'
            {...register('sedeID', {
              required: true,
            })}
          >
           
           {sedes.map((cate) =>
            <><option key={cate.id} value={cate.id}>
            {cate.descripcion}
            </option></>)}
          </select>
 
</div>


<div class="input-group mb-3">
  <div class="input-group-prepend">
    <label class="input-group-text" for="inputGroupSelect01">Unidad de Destino</label>
  </div>
  <select
            className="custom-select" id='sedeID'
            {...register('destino', {
              required: true,
            })}
          >
           
           {destinos.map((cate) =>
            <><option key={cate.id} value={cate.id}>
            {cate.descripcion}
            </option></>)}
          </select>
 
</div></>:""}
  


    <div class="input-group mb-3">
  <div class="input-group-prepend">
    <label class="input-group-text" for="inputGroupSelect01">Nivel de Acceso</label>
  </div>
  <select
            className="custom-select" id='nivel'
            {...register('nivel', {
              required: true,
            })}
          >
           
           {Usuario.nivel=="1"?<><option value="1">Super User</option>
            <option value="2">Administrador</option></>:""} 
            <option value="3">Operador</option>
            <option value="4">Observador</option>
          </select>
 
</div>


    <div class="input-group mb-3">
  <div class="input-group-prepend">
    <label class="input-group-text" for="inputGroupSelect01">Estado</label>
  </div>
  <select
              className="custom-select" id='estado'
              {...register('estado', {
                required: true,
              })}
            >
              <option value="1">Activo</option>
              <option value="2">Suspendido</option>
             
            </select>
 
</div>
  
   
    

    <input type="submit" value="Crear Usuario"  className="btn btn-primary" id="btnSalir" />
        </section>
       
      
      
        
       
        </form>
    </div></div>
  );
};
export default ModuloCreaUsuario;
