import axios from 'axios';
import Swal from 'sweetalert2'
import obtieneDatos  from '../tools/obtieneDatosCookiesUsuario'
const horasMaximasDocenteCivil=36;
const horasMaximasDocenteRetirado=10;
const horasMaximasDocenteEnActividad=12;
const Usuario=obtieneDatos();
console.log(Usuario)
  var rentadas=""
const cambiaDocente =async(datos)=>{

const realizaCambios=async(nuevaAsignacion,viejaAnulada)=>{
  
  const clienteAnulado = axios.create({
      
    baseURL:"https://wwwa.isepsantafe.edu.ar/expedientes/docentes/anulaAsignacion"
    });
    const client = axios.create({
       
      baseURL:"https://wwwa.isepsantafe.edu.ar/expedientes/materias/asignaMateria/"
      });
    try {
      const anulacion=await clienteAnulado.post('/'+viejaAnulada.id,viejaAnulada)
    
    if(anulacion)
      {
        
        const transaccion=await client.post('/',nuevaAsignacion)
        if(transaccion){
          const clientLog = axios.create({
       
            baseURL:"https://wwwa.isepsantafe.edu.ar/expedientes/docentes/creaLogCambioDocente/"
            });
          console.log(transaccion.data)
          const fechaHoy=new Date()
      
          // Obtener el año, el mes y el día
          const year = fechaHoy.getFullYear();
          const month = String(fechaHoy.getMonth() + 1).padStart(2, '0'); // getMonth() devuelve el mes de 0 a 11
          const day = String(fechaHoy.getDate()).padStart(2, '0');
          
          // Formatear la fecha en YYYY-MM-DD
          const formattedDate = `${year}-${month}-${day}`;
const log={
  fechaCambio:formattedDate,
  usuarioCambio:Usuario.id,
  docenteInicial:viejaAnulada.docenteDni,
  docenteFinal:nuevaAsignacion.docenteDni,
  idAsignaInicial:viejaAnulada.id,
  idAsignaRemplazo:transaccion.data.id,
  fechaInicialMateria:viejaAnulada.fechaInicio,
  fechaFinalMateria:nuevaAsignacion.fechaFinalizacion

}


          const creaLog=await clientLog.post('/',log)

         
           Swal.fire({
             text:"Sustitución Docente",
             title:"Cambio realizado correctamente",
         buttons:"Aceptar",
         timer: "4000",
             icon:"success"}).then(()=>{return "caca"})
           

      }
    }
    
    
    
    
    }catch{}
}




    const consultarDocenteAsignaMateria=async(dniDocente,fechaMaximaInicio,fechaMaximaFinalizacion,DatosMateriaHeredada)=>{
        const infoConsulta={
          "docenteDni":dniDocente,
          "fechaMaximaInicio":fechaMaximaInicio,
          "fechaMaximaFinalizacion":fechaMaximaFinalizacion
        }
        
    
        const client = axios.create({
       
          baseURL:"https://wwwa.isepsantafe.edu.ar/expedientes/docentes/puedeDarHoras"
          });
          try {
           
            const resultadoConsulta=await client.post('/',infoConsulta)
      
      if(resultadoConsulta.data.docente==""){
      
      alert("Docente Inexistente en el sistema, gestionelo!!!!!")
      }
      else{
       
       const fechaHoy=new Date()
      
       // Obtener el año, el mes y el día
       const year = fechaHoy.getFullYear();
       const month = String(fechaHoy.getMonth() + 1).padStart(2, '0'); // getMonth() devuelve el mes de 0 a 11
       const day = String(fechaHoy.getDate()).padStart(2, '0');
       
       // Formatear la fecha en YYYY-MM-DD
       const formattedDate = `${year}-${month}-${day}`;
  
        var horasMaximas=0;
        

         if(resultadoConsulta.data.docente[0].docenteTipo=="Policia Actividad"){
            horasMaximas=horasMaximasDocenteEnActividad
          }else if(resultadoConsulta.data.docente[0].docenteTipo=="Policia Retirado"){
            horasMaximas=horasMaximasDocenteRetirado
          }else{
            horasMaximas=horasMaximasDocenteCivil
          }
          if(resultadoConsulta.data.horas<horasMaximas&&resultadoConsulta.data.docente[0].docenteEstado=="Activo")
          {
        
       const  horasDisponibles=horasMaximas-resultadoConsulta.data.horas
        
       const continuoLasPreguntas=(condicion)=>{
        {
        
          try {
            
              Swal.fire({ 
               
                title: "Las horas que asignara son rentadas?",
                icon: "question",content: "date", closeOnClickOutside: false, 
                showDenyButton: true,
        showCancelButton: true,
        
        confirmButtonText: "Si, lo son",
        denyButtonText: `No, son horario de servicio`,
        cancelButtonText:`Cancelar`
                  
              }).then((ca) => {segundaPregunta(ca)})
            
             
           
     
    const segundaPregunta=async(respuestaRentadas)=>{
     
      if(respuestaRentadas.isConfirmed){
        rentadas="SI"
       }else{
        rentadas="NO"
       }
       console.log(rentadas)
      Swal.fire({ 
              
          title: "El cambio es a partir de hoy?",
          icon: "question",content: "date", closeOnClickOutside: false, 
          showDenyButton: true,
  showCancelButton: true,
  
  confirmButtonText: "No, es de otro dia",
  denyButtonText: `Si, es de Hoy`,
  cancelButtonText:`Cancelar`
            
        }).then((result) => {
          if (result.isConfirmed) {
         
            Swal.fire({
              title: "Indique en que fecha fue",
              icon: "question",
              showCancelButton: true,
              cancelButtonText:`Cancelar`,
              closeOnClickOutside: false,
              html: `
    <input
      type="date"
      value=${DatosMateriaHeredada.fechaInicio}
      min=${DatosMateriaHeredada.fechaInicio}
      max=${formattedDate}
      class="swal2-input"
      id="range-value" >
      `,
                  
               
                
            }).then((result) => {
              if (result.isConfirmed) {continuo(document.getElementById('range-value').value)}})
           }else if(!result.isDismissed){
           
            continuo(formattedDate)

          }
        });
    } 
     const continuo=async(fecha)=>{
     

const viejaAsignacionMateriaDatos={
id:DatosMateriaHeredada.id,
docenteDni:DatosMateriaHeredada.docenteDni,
fechaFinalizacion:fecha,
fechaInicio:DatosMateriaHeredada.fechaInicio,
usuarioID:Usuario.id,
sedeID:Usuario.sedeID,
dependenciaID:Usuario.destino,
estado:"Anulada"
}
const asignacionMateriaDatos={
  idMateria:DatosMateriaHeredada.idMateria,
  division:DatosMateriaHeredada.division,
  docenteDni:dniDocente,
  sedeCursada:DatosMateriaHeredada.sedeCursada,
  horasAsignadas:DatosMateriaHeredada.horasAsignadas,
  fechaInicio:fecha,
  fechaFinalizacion:DatosMateriaHeredada.fechaFinalizacion,
  usuarioID:Usuario.id,
  sedeID:Usuario.sedeID,
  dependenciaID:Usuario.destino,
  estado:"Activo",
  esRentada:rentadas

}



     realizaCambios(asignacionMateriaDatos,viejaAsignacionMateriaDatos)

     }
     
     
            
             
           }catch{
     
           }










      // asignoMateria(resultadoConsulta.data,materia.data)
 
       
       }}
      
         if(horasDisponibles>=DatosMateriaHeredada.horasAsignadas){
continuoLasPreguntas(false)

         }else{
          Swal.fire({ 
                
            title: "Docente Excedido de horas para esta materia, solo va a poder asignarlas si no son rentadas, quiere continuar?!",
            icon: "question", closeOnClickOutside: false, 
           
    showCancelButton: true,
    
    confirmButtonText: "Si continuo igual",
    cancelButtonText:`Cancelar`
              
          }).then((result) => {
            if (result.isConfirmed) {

             continuoLasPreguntas(true)
}
         }
        )}        
  
         }else{
         
        
          alert("Docente con la cantidad de horas maximas para ese periodo o Inactivo Actualmente")
         }
      }
      
           
            }
          catch (error) {}
      
      
      
      
      }
   

    const client = axios.create({
    
        baseURL:"https://wwwa.isepsantafe.edu.ar/expedientes/docentes/devuelveIdAsignacion/"
        });
        try {
         const datosRecuperados= await client.post('/'+datos.materiaAsignadaId)

if(datosRecuperados){

    const inforMateria=datosRecuperados.data.respuesta[0]
    const valor = window.prompt("Ingrese el nro de DNI del docente para asignar las horas horas ")
    if(valor){
 consultarDocenteAsignaMateria(valor,inforMateria.fechaInicio,inforMateria.fechaFinalizacion,inforMateria)

    }
}
        }catch{}
      





    
        

}
export default cambiaDocente

