import devuelveUsuario from '../obtieneDatosCookiesUsuario'
const convierteCadete=(cadete,estado)=>{
   const usuario=devuelveUsuario()
  
    return {

      cadeteEstado: estado,
      promocion:cadete.promocion,
      idUsuario:usuario.id
    }
}
export default convierteCadete;