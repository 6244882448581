import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
//*import './GestorUsuariosCalificaciones.css'
import Modal from 'react-modal';
const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const { register, handleSubmit, reset } = useForm();
const [modalAbierto,setModalAbierto]=useState(false)


const customStyles = {
    content: {
        
      top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width:"auto",
    height:"auto"
  
    },
  };
  useEffect(() => {
    // Fetch users from the API
    axios.post('https://wwwa.isepsantafe.edu.ar/expedientes/calificaciones/devuelveUsuarios')
      .then(response => {
        console.log(response.data.usuariosConsultados)
        setUsers(response.data.usuariosConsultados)
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);

  const handleEditClick = (user) => {
    setModalAbierto(true)
    setSelectedUser(user);
    reset(user); // Populate the form with the selected user's data
  };

  const onSubmit = (data) => {
    axios.put(`/api/users/${data.dni}`, data)
      .then(response => {
        // Update the local state
        setUsers(users.map(user => user.dni === data.dni ? data : user));
        setSelectedUser(null);
      })
      .catch(error => {
        console.error('Error updating user:', error);
      });
  };
const closeModalInformeDocentes=()=>{
    setModalAbierto(false)
}
  return (
    <>
    <Modal
    isOpen={modalAbierto}
    onRequestClose={closeModalInformeDocentes}
  
    ariaHideApp={false}
  > <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={()=>setModalAbierto(false)}>x </button> <a style={{alignSelf:"center"}}><strong>Edicion Usuario gestion Calificaciones. </strong></a>
</div>
        <div class="formUsuario"> 
          <h2>Editar Usuario</h2>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label>DNI:</label>
              <input {...register('dni')} disabled />
            </div>
            <div>
              <label>Apellido:</label>
              <input {...register('apellido')} />
            </div>
            <div>
              <label>Nombres:</label>
              <input {...register('nombres')} />
            </div>
            <div>
              <label>Email:</label>
              <input type="email" {...register('email')} />
            </div>
            <div>
              <label>Telefono:</label>
              <input {...register('telefono')} />
            </div>
            <div>
              <label>Estado:</label>
              <input {...register('estado')} />
            </div>
            <div>
              <label>Blanqueo Contraseña:</label>
              <input {...register('blanqueoContrasena')} />
            </div>
            <button type="submit">Guardar</button>
          </form>
        </div>
  </Modal>
    <div>
      <h1>Gestion de Usuarios del Sistema de Calificaciones ISeP</h1>
      <table>
        <thead>
          <tr>
            <th>DNI</th>
            <th>Apellido</th>
            <th>Nombres</th>
            <th>Email</th>
            <th>Telefono</th>
            <th>Estado</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.dni}>
              <td>{user.dni}</td>
              <td>{user.apellido}</td>
              <td>{user.nombres}</td>
              <td>{user.email}</td>
              <td>{user.telefono}</td>
              <td>{user.estado}</td>
              <td>
                <button onClick={() => handleEditClick(user)}>Editar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedUser?()=>setModalAbierto(true):""}
    </div></>
  );
};

export default UserManagement;
