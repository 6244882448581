import ObtieneDatosCookies from "../tools/obtieneDatosCookiesUsuario";

const convierteCadete=(informacion)=>{

  const usuario=ObtieneDatosCookies()
    
    return {

      idUsuario:usuario.id,
      idSede:usuario.sedeID,
      motivo:informacion.motivo,
      descripcion:informacion.descripcion,
      promocionBaja:informacion.promocionBaja,
      resolucionBaja:informacion.resolucionBaja,
      fechaBaja:informacion.fechaBaja,
      promocionAlta:informacion.promocion,
      resolucionAlta:informacion.resolucionAlta,
      fechaAlta:informacion.fechaAlta,
      notificado:informacion.notificado,
      cadeteDni: informacion.cadeteDni,
      cadeteApellido: informacion.cadeteApellido,
      cadeteNombre: informacion.cadeteNombre,
      cadeteDomicilio: informacion.cadeteDomicilio,
      cadeteCelular: informacion.cadeteCelular,
      cadeteGenero: informacion.cadeteCelular,
      cadeteSede: informacion.cadeteSede,
      cadeteEstado: "Regular",
      promocion:informacion.promocion 
                       
    }
}
export default convierteCadete;