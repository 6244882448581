import React from 'react';
import {Document, Page, View,Text, PDFViewer,Image} from '@react-pdf/renderer';
import ImagenQR from '../tools/imagenQR';
import Logo from '../imagenes/escudoIseP.png'
import Cookies from 'universal-cookie';
import ObtieneDatosCookiesExpediente from './obtieneDatosCookiesExpediente';
import devuelveDependenciaConstultada from '../tools/devuelveDependencia';
import devuelveCategorias from '../modulos/devuelveCategorias';
import regresaBienFecha from '../tools/regresaBienFecha'
import devuelveSede from '../tools/devuelveSede';


function Legajo() {
 

  const cookies=new Cookies();
 
  

  const datosVarios=  ObtieneDatosCookiesExpediente() 
  const direccion=datosVarios.idExpediente;
       return (
      <PDFViewer showToolbar="false" style={{width:"100%",height:"100vh"} } >
      <Document  title={"Caratula expediente "+datosVarios.idExpediente} >
        <Page size="A4" >
          <View style={{width:"100%",height:"100%"} }> 
         <View style={{margin:"20px",border:"2px solid grey"}}>
        <View id="Escudo"style={{alignSelf:"center", height:"75px", width:"75px", marginTop:"10px"}}>
        <Image src={Logo}/></View>
        
        <Text id='nombre escuela' style={{alignSelf:"center", marginTop:"-5px"}}>
          Instituto de Seguridad Pública</Text>
          <Text  style={{alignSelf:"center", fontSize:"10px", marginTop:"10px"}}>
          Expedientes bajo Sistema de Gestión</Text>
        <View className="Legajo"style={{ margin:"50px"}}>
             <View className='datosPersonales_legajo' style={{textAlign:"left",fontSize:"12px",lineHeight:"2px", margin:"25px",padding:"25px"}}>

    <Text className='sub_titulo_legajo' style={{color:"grey"}}>Expediente Nro: <Text style={{color:"black"}}>{ datosVarios.idExpediente}</Text></Text>
    <Text style={{color:"grey"}}>Causante:  <Text style={{color:"black"}}> {datosVarios.iniciador}</Text></Text>
    <Text style={{color:"grey"}}>Dependencia Iniciadora:<Text style={{color:"black"}}>{devuelveDependenciaConstultada().filter(dendencias=>dendencias.id==datosVarios.dependenciaIdExpediente)[0].descripcion+" ("+devuelveSede().filter(sede=>sede.id==datosVarios.idSedeExpediente)[0].descripcion+") "}</Text></Text>  
    <Text style={{color:"grey"}}>Motivo de Inicio de Expediente: <Text style={{color:"black"}}>{devuelveCategorias().filter(categoria=>categoria.id==datosVarios.idCategoria)[0].descripcion}</Text></Text>
    <Text style={{color:"grey"}}>Concepto: <Text style={{color:"black"}}>{datosVarios.concepto.length>240?datosVarios.concepto.slice(0,240)+" ...":datosVarios.concepto}</Text></Text>
    <Text style={{color:"grey"}}>Inicio con otro nro de Expediente o nota: <Text style={{color:"black"}}>{datosVarios.tieneExptAnterior != "NO" ? "SI de tipo "+datosVarios.tieneExptAnterior +" bajo numero "+datosVarios.idExptAnterior:"NO"}</Text></Text>
    <Text style={{color:"grey"}}>Posee nro de Expediente Ministerial:<Text style={{color:"black"}}>{datosVarios.idExpMinisterio != "null" ? "SI nro "+datosVarios.idExpMinisterio:"NO"}</Text></Text> 
    </View>

    
      

  

  </View>
  <View id="QR" style={{ marginLeft:"42%" }}>       
    <ImagenQR direccion={direccion} tamaño="250"/>
    </View>
    <View style={{paddingBottom:"42px"}}>
      <Text style={{fontSize:"8px", margin:"2px", textAlign:"right"}}>Fecha de Generacion:{regresaBienFecha(datosVarios.fechaGeneracion)}</Text>
    </View>
  </View> 
  </View>
        </Page>
      </Document>
      </PDFViewer>

    );
  }
export default Legajo