import Cookies from 'universal-cookie';
const cookies =new Cookies;

const seteaCokieFirstStep=(respuesta)=>{
    cookies.set("id",respuesta.id, {path:"/"}) 
    cookies.set("pass",respuesta.pass, {path:"/"}) 
    cookies.set("identificacion",respuesta.identificacion, {path:"/"}) 
    cookies.set("estado",respuesta.estado, {path:"/"}) 
    cookies.set("blanqueoContrasena",respuesta.blanqueoContrasena, {path:"/"})       
}
export default seteaCokieFirstStep;