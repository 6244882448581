import React, { useState, useEffect } from 'react';

import devuelveDependenciaConstultada from '../tools/devuelveDependencia'
import devuelveSede from '../tools/devuelveSede';

export default function DevuelveMovimiento(props) {
 
  const movimiento=props.datos

  
  if(movimiento==null||movimiento.length<1){
    return (
      <div style={{"font-weight": "800","font-size":"36px"}}>"Sin smovimientos aun"</div>)
   
  
  }
const date=new Date(movimiento.fechaSalida)
const fechaInicio=new Date("01/01/2022")
const date2=new Date(movimiento.fechaEntrada)
  return (
    <div>
      <div classname="contenedor_Expediente">
        <h4>Ultimo Movimiento:</h4>
        {movimiento.esArchivo!="PASO A ARCHIVO"&&movimiento.esArchivo?
<h4 style={{textAlign:"center", color:"green"}}>Afuera del Instituto</h4>:""}



      </div>
<td style={{"text-align": "left"}}>
{movimiento.esArchivo=="PASO A ARCHIVO"?<>
<h2 style={{textAlign:"center", color:"red"}}>ARCHIVADO</h2>
<li style={{"font-weight": "800"}}>Dependencia de Archivo: <a style={{"font-weight": "100"}}>
  {devuelveDependenciaConstultada().filter(categoria=>categoria.id==movimiento.destinoSalidaID)[0].descripcion + " ("+devuelveSede().filter(categoria=>categoria.id==movimiento.sedeSalidaID)[0].descripcion +")"}
    </a></li>
    <li style={{"font-weight": "800"}}>desde fecha: <a style={{"font-weight": "100"}}>
  {date.toLocaleDateString()}
    </a></li>
    <li style={{"font-weight": "800"}}>Fojas: <a style={{"font-weight": "100"}}>
  {movimiento.fojasActuales?movimiento.fojasActuales:"-"}
    </a></li>
    <li style={{"font-weight": "800"}}>Observacion: <a style={{"font-weight": "100"}}>
  {movimiento.comentarioSalida?movimiento.comentarioSalida:"-"}
    </a></li>
   
   






</>:<>

<li style={{"font-weight": "800"}}>Proveniente de: <a style={{"font-weight": "100"}}>
  {devuelveDependenciaConstultada().filter(categoria=>categoria.id==movimiento.destinoSalidaID)[0].descripcion + " ("+devuelveSede().filter(categoria=>categoria.id==movimiento.sedeSalidaID)[0].descripcion +")"}
    </a></li>
    <li style={{"font-weight": "800"}}>en fecha: <a style={{"font-weight": "100"}}>
  {date.toLocaleDateString()}
    </a></li>
    <li style={{"font-weight": "800"}}>Fojas Actuales: <a style={{"font-weight": "100"}}>
  {movimiento.fojasActuales?movimiento.fojasActuales:"-"}
    </a></li>
    <li style={{"font-weight": "800"}}>Observacion de Salida: <a style={{"font-weight": "100"}}>
     
  {movimiento.comentarioSalida?movimiento.comentarioSalida:"-"}
    </a></li>
    <li style={{"font-weight": "800"}}>Observacion de Recepcion: <a style={{"font-weight": "100"}}>
  {movimiento.comentario?movimiento.comentario:"-"}
    </a></li>
    <li style={{"font-weight": "800"}}>Destino Actual: <a style={{"font-weight": "100"}}>
  {movimiento.destinoEntradaID?devuelveDependenciaConstultada().filter(categoria=>categoria.id==movimiento.destinoEntradaID)[0].descripcion + " ("+devuelveSede().filter(categoria=>categoria.id==movimiento.sedeEntradaID)[0].descripcion +")":movimiento.esArchivo?movimiento.esArchivo:"En transito"}
    </a></li>
    <li style={{"font-weight": "800"}}>desde fecha: <a style={{"font-weight": "100"}}>
  {date2>fechaInicio?date2.toLocaleDateString():"-"}
    </a></li>







</>}
 
    
  </td>
  <div>
    </div>


 </div>
  );
}


