
import axios from 'axios';

const devuelvoUsuario=async(idUsuario)=>{
   
    const client = axios.create({
        baseURL: "https://wwwa.isepsantafe.edu.ar/expedientes/usuariosPorID"
        });
        try {
          const response = await client.post('/'+idUsuario);
  if (response){
const usuario=response.data.apellido + ", "+response.data.nombre
return usuario
  }
         }
        catch (error) {}}
export default devuelvoUsuario