import * as React from 'react';
import {useState} from 'react'
import '../cadetes/cosa.css';
import axios from 'axios';
import csvToJson from 'csvtojson'
import { Spinner } from 'reactstrap';
import swal from 'sweetalert'
import { CSVLink } from "react-csv";

function CargaMasiva(props) {
  function tieneTodosLosCampos(Listado, camposRequeridos) {
    if (!Array.isArray(Listado)) {
      throw new Error('El primer argumento debe ser un array');
    }
  
    if (!Array.isArray(camposRequeridos) || !camposRequeridos.every(field => typeof field === 'string')) {
      throw new Error('El segundo argumento debe ser un array de strings');
    }
    return Listado.every(item => {
      const itemFields = Object.keys(item);
      return (
        itemFields.length === camposRequeridos.length &&
        camposRequeridos.every(field => item.hasOwnProperty(field))
      );
    });
  }
  
 

  const camposObligatorios = [ 'dniCertificado', 'apellidoNombre','sedeCertificado','grado'];

  const headers = [
    { label: "dniCertificado", key: "dniCertificado" },
    { label: "apellidoNombre", key: "apellidoNombre" },
    { label: "sedeCertificado", key: "sedeCertificado" },
    { label: "grado", key: "grado" }
   
   
  ];

  
  
  function comparaExistencias(listado1, listado2, campoValor) {
    
    if (!Array.isArray(listado1) || !Array.isArray(listado2)) {
      throw new Error('Ambos argumentos deben ser arrays');
    }
  
    const conjunto1 = new Set(listado1.map(item => item[campoValor]));
    const conjunto2 = new Set(listado2.map(item => item[campoValor]));
  
    const repetidos = [];
    const paraAgregar = [];
  
   listado1.forEach(item => {
      if (conjunto2.has(item[campoValor])) {
        repetidos.push(item);
      } else {
        paraAgregar.push(item);
      }
    });
  
   listado2.forEach(item => {
      if (!conjunto1.has(item[campoValor])) {
        paraAgregar.push(item);
      }
    });
  
    return {
      repetidos: repetidos,
      paraAgregar: paraAgregar
    };
  }
 

  const enviaLista=async(listado) => {
    if(tieneTodosLosCampos(listado, camposObligatorios)){
      alert("Archivo aceptado...Comparando Listado con los existentes")

      
        try {
         console.log(listado)
         console.log(props.ListaExistente)
    const listadosComparados = comparaExistencias(listado, props.ListaExistente, 'dniCertificado');
    console.log(listadosComparados)
     if(listadosComparados.paraAgregar.length>0){
      swal({
        title: "Hay elementos "+listadosComparados.paraAgregar.length + " para agregar. CONTINUAMOS?",
        closeOnClickOutside: false,  buttons: {
          cancel: "No",
          confirm: "Si"}
      }).then(respuesta=> continua(respuesta)); 
      const continua=(respuesta)=>{
        if(respuesta){
          envia(listadosComparados.paraAgregar)
        }
      }
     }else{
      alert("no hay nada nuevo que agregar")
     }
    
          }
        catch (error) {}


    }
else{
  alert("archivo con campos incorrectos, por favor compruebe el nombre de las columnas y respetar los simbolos")
}
   }
   const envia=async(data)=>{
    try {
      const client = axios.create({
        baseURL:"https://wwwa.isepsantafe.edu.ar/expedientes/certificados/creaMasivo"
        });
      const enviados=await client.post('/'+props.cursoActual.nombreCurso,data)
      if(enviados){
       alert("Incorporacion finalizada con: "+enviados.data.Contador)
       props.myID("dale")
      }
    } catch (error) {
      
    }
   
          }
  const volver=()=>
  {
    
    props.myID("dale")
  }
  

   
  const [cargando, setCargando] = useState(false);
     const [file, setFile] = useState();
     const [cadetes, setCadetes] = useState();
     const fileReader = new FileReader();
  

     
     const subirArchivoCSV =(e)=>{
try{
  var reader=new FileReader();
  reader.readAsText(e.target.files[0]);
  reader.onload=()=>{

    const simbolo=","
    const simpolo2=";"
   
    csvToJson({
        
      checkType:true,
      delimiter:simpolo2
  })
  .fromString(reader.result)
  .subscribe((jsonObj)=>{
  
  }).then(f=>subirArchivo(f))


 }
   
  
  reader.onerror=error=>{
      console.log("Error: ",error)

  }
  
}catch{

}
      
    }
   
    const subirArchivo=(f)=>{

      enviaLista(f)

    }



  return (
<>
    <div className=''>

          <div className=' '>

  
<h1 >Modulo de Upload de archivos CSV</h1>
<br></br>

<form>
{cargando && (<Spinner color='priority'/>)}
{!cargando && <input className='btn btn-success'
    type={"file"}
   name='lista'
    id={"csvFileInput"}
    accept={".csv"}
   onChange={subirArchivoCSV}
  />}
  
  <CSVLink data={[]} filename={"Ejemplo Cursantes"} style={{marginTop:"4px",marginLeft:"4px"}}className="btn btn-success"separator={";"}
      target="_blank" headers={headers}> Archivo de ejemplo</CSVLink>

</form>

<br />



 <input style={{padding:"15px"}} type="button" value="Cancelar" className="btn btn-secondary" onClick={volver}/>
 </div>
        </div>
       


</>
  );
}
export default CargaMasiva;
