import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies =new Cookies;
const devuelveDependenciaConstultada=async()=>{
    const client = axios.create({
        baseURL:"https://wwwa.isepsantafe.edu.ar/expedientes/dependencias"
        });
        try {
        
  //+" descripcion: "+dependencias.data[i].descripcion
            let   dependencias= await client.post("/")
         if(dependencias.data){
            cookies.set("cantidadDependencias",dependencias.data.length, {path:"/"}) 
            for (var i = 0; i < dependencias.data.length; i++) {
                cookies.set("D"+i,dependencias.data[i].descripcion, {path:"/"}) 
            }
            const dependenciasRecuperadas=[]
           
            for (var j = 0; j < cookies.get('cantidadDependencias', {path:"/"}); j++) {
             
              dependenciasRecuperadas.push(cookies.get("D"+i, {path:"/"}))
             
            }
         
           
          
            
return dependenciasRecuperadas
         }
        
        
        }
        catch (error) {}
    
    }
    export default devuelveDependenciaConstultada