const regresaBienFechaYDia=(dato)=>{
   
    if(dato){
        const [dia, mes, ano] = dato.split("-");
        const [a,b]=ano.split("T")
        const [x,y,z]=b.split(":")
    
        const mesReal=parseInt(mes)-1;
        const horaReal=parseInt(x)-3;
        const date4 = new Date(parseInt(dia), parseInt(mesReal),parseInt(a),parseInt(horaReal), parseInt(y)); 
        const horita=date4.toLocaleTimeString()
        const [hora,minutos,segundos]=horita.split(":")
    const fechita=date4.toLocaleDateString()
    
        let cambiada = fechita + " a las "+hora+":"+minutos;
        return cambiada
    }else{
        return"-"
    }
   
}
export default regresaBienFechaYDia
