import ObtieneDatosCookies from "../tools/obtieneDatosCookiesUsuario";

const convierteCadete=(informacion)=>{
  const usuario=ObtieneDatosCookies()

    return {

      cadeteDni: informacion.cadeteDni,
      cadeteApellido: informacion.cadeteApellido,
      cadeteNombre: informacion.cadeteNombre,
      cadeteDomicilio: informacion.cadeteDomicilio,
      cadeteCiudad: informacion.cadeteCiudad,
      cadeteUR: informacion.cadeteUR,
      cadeteCelular: informacion.cadeteCelular,
      cadeteGenero: informacion.cadeteGenero,
      cadeteSede: informacion.cadeteSede,
      cadeteEstado: informacion.cadeteEstado,
      promocion:informacion.promocion,
      idUsuario:informacion.idUsuario?informacion.idUsuario:null
                       
    }
}
export default convierteCadete;