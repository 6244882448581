
import Cookies from 'universal-cookie';
const cookies =new Cookies;




const devuelveDependenciaConstultada=()=>{
    let contador=1
    const depe=[]
    for (var i = 0; i < cookies.get('cantidadDependencias', {path:"/"}); i++) {
             
        depe.push({"id":(contador),"descripcion":cookies.get("D"+i, {path:"/"})})
       contador++
      }

     
      depe.sort(function (a, b) {
        return a.descripcion.localeCompare(b.descripcion);
      });
        
        return  depe
    }
    export default devuelveDependenciaConstultada