import Papa from 'papaparse'

function convierteDataProvincia(datos){

function transformData(data) {
    return data.map(entry => ({
        id: entry['Documento'] || '',  // Usamos 'Documento' como 'id'
        dni: entry['Documento'] || '',
        email: entry['Email'] || '',
        cuil: entry['CUIL'] || '',
        nombres: entry['Apellido y Nombre'] ? entry['Apellido y Nombre'].split(', ')[1] : '',
        apellido: entry['Apellido y Nombre'] ? entry['Apellido y Nombre'].split(', ')[0] : '',
        genero: entry['Genero'] || '',
        estadoCivil: entry['Estado Civil'] || '',
        fechaDeNacimiento: entry['Nacimiento'] || '',
        argentinoNativo: entry['Nacionalidad'] === 'Argentina' ? 'Sí' : 'No',
        provinciaNacimiento: entry['ProvNac'] || '',
        lugarDeNacimiento: entry['LocNac'] || '',
        apellidoNombreMadre: entry['Madre'] || '',
        apellidoNombrePadre: entry['Padre'] || '',
        resideProvincia: entry['Reside en StaFe'] === 'Sí' ? 'Sí' : 'No',
        localidad: entry['Localidad'] || '',
        noResideProvincia: entry['Reside en StaFe'] === 'No' ? 'Sí' : 'No',
        departamento: entry['Departamento'] || '',
        domicilio: entry['Domicilio'] || '',
        telefono_1: entry['Telefono'] || '',
        telefono_2: entry['Tel. Alternativo'] || '',
        comisaria: '',  // Este campo no tiene equivalente en la entrada proporcionada
        nivelEstudio: '',  // Este campo no tiene equivalente en la entrada proporcionada
        tituloSecundario: entry['Tit. excluyente']||'',  // Este campo no tiene equivalente en la entrada proporcionada
        fechaTituloSecundario: entry['Fecha Tit.'] || '',
        tituloSuperior: entry['Titulo Mayor Nivel'] || '',
        fechaTituloSuperior: entry['Fecha Tit.Mayor Nivel'] || '',
        pregunta1:entry['Servicio Gobierno']|| '',  // Este campo no tiene equivalente en la entrada proporcionada
        pregunta2: entry['Destituido Ad.Publica']||'',  // Este campo no tiene equivalente en la entrada proporcionada
        pregunta3:entry['Condenado']|| '',  // Este campo no tiene equivalente en la entrada proporcionada
        pregunta4: entry['Procesado']||''   // Este campo no tiene equivalente en la entrada proporcionada
    }));
}
function exportToCsv(data) {
    const csv = Papa.unparse(data);

    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");

    if (link.download !== undefined) { // Feature detection
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "export.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
}
// Ejemplo de uso
const data = [
    {
        "Apellido y Nombre": "Perez, Juan",
        "Documento": "12345678",
        "Genero": "Masculino",
        "Estado Civil": "Soltero",
        "Nacimiento": "1990-01-01",
        "LocNac": "Rosario",
        "ProvNac": "Santa Fe",
        "Domicilio": "Calle Falsa 123",
        "Provincia": "Santa Fe",
        "Localidad": "Rosario",
        "Departamento": "Centro",
        "Telefono": "3411234567",
        "Tel. Alternativo": "3417654321",
        "Email": "juan.perez@example.com",
        "CUIL": "20-12345678-1",
        "Nacionalidad": "Argentina",
        "Madre": "Perez, Maria",
        "Padre": "Perez, Jose",
        "Reside en StaFe": "Sí",
        "Tit. excluyente": "",
        "Fecha Tit.": "2008-12-01",
        "Titulo Mayor Nivel": "Ingeniero",
        "Fecha Tit.Mayor Nivel": "2015-12-01",
        "Servicio Gobierno": "",
        "Destituido Ad.Publica": "",
        "Condenado": "",
        "Procesado": "",
        "Boleto Educativo": ""
    },    {
        "Apellido y Nombre": "Perez, Juan",
        "Documento": "12345678",
        "Genero": "Masculino",
        "Estado Civil": "Soltero",
        "Nacimiento": "1990-01-01",
        "LocNac": "Rosario",
        "ProvNac": "Santa Fe",
        "Domicilio": "Calle Falsa 123",
        "Provincia": "Santa Fe",
        "Localidad": "Rosario",
        "Departamento": "Centro",
        "Telefono": "3411234567",
        "Tel. Alternativo": "3417654321",
        "Email": "juan.perez@example.com",
        "CUIL": "20-12345678-1",
        "Nacionalidad": "Argentina",
        "Madre": "Perez, Maria",
        "Padre": "Perez, Jose",
        "Reside en StaFe": "Sí",
        "Tit. excluyente": "",
        "Fecha Tit.": "2008-12-01",
        "Titulo Mayor Nivel": "Ingeniero",
        "Fecha Tit.Mayor Nivel": "2015-12-01",
        "Servicio Gobierno": "",
        "Destituido Ad.Publica": "",
        "Condenado": "",
        "Procesado": "",
        "Boleto Educativo": ""
    }
    // más objetos
];

const transformedData = transformData(datos);
exportToCsv(transformedData)
return transformedData
}
export default convierteDataProvincia