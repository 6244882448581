
import ModuloDevuelveListaExpedientes from './ModuloDevuelveListaExpedientes.jsx';



function FormNuevoExpte (props) {

const tomoElDato=async(dato)=>{
await   props.myID(dato)
}

  return (
    <div >
     
  <div style={{overflow:"auto", height:"390px", width: "100%", border:"1px solid black"}} >
    <ModuloDevuelveListaExpedientes cosas={props.listaExpedientes}  valor={(datillo)=>tomoElDato(datillo)}/>
  </div>
  
                    <div className="contenedor-boton" >

        

<input type="button" value="Cancelar"  className="btn btn-warning" onClick={()=>{props.myID("dale")}} />

</div>

    </div>
  );
};
export default FormNuevoExpte;
