import React, { useState } from 'react';

const CargaDNI = (props) => {
    const [values, setValues] = useState('');

    const handleInputChange = (event) => {
      setValues(event.target.value);
    };
  
    const handleSubmit = () => {
      const valuesArray = values.split('\n').map(value => value.trim()).filter(value => value !== '');
      props.datos(valuesArray)
      setValues("")
    };
  
    return (
      <div>
        <textarea
          value={values}
          onChange={handleInputChange}
          placeholder="Pega los valores aquí (cada  valor en una nueva línea)..."
          rows="10"
          cols="30"
        />
        
                                
        <br />
        <button onClick={handleSubmit}>Subir</button>
      </div>
    );
  };

export default CargaDNI;
