import { useForm } from 'react-hook-form';
import axios from "axios";
import Cookies from 'universal-cookie';
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario.js';
import devuelveDependenciaConstultada from '../tools/devuelveDependencia.js';
import devuelveCategorias from '../modulos/devuelveCategorias.js';
import Imagen64 from '../tools/imagenABase64.js'
import { useState } from 'react';



function FormNuevoExpte (props) {
  const cookies=new Cookies();
  const ID=cookies.get('id');

  const [disableBotonFinalizar, setDisableBotonFinalizar]=useState(false)
  const [firma1, setFirma1]=useState("")
  const [firma2, setFirma2]=useState("")
  const [firma3, setFirma3]=useState("")
  const [firma4, setFirma4]=useState("")
  const [firma5, setFirma5]=useState("")
  const fecha = new Date();
const hoy = "Fecha: "+fecha.toLocaleDateString();

  const URI='https://wwwa.isepsantafe.edu.ar/expedientes/'
  
const parametro={}
const usuario=ObtieneDatosCookies()
  

  

  const {unregister,register, formState: { errors }, watch, handleSubmit,} = useForm({
  defaultValues: parametro//recupero lo que pasa por parametros
  });
  
const categorias=devuelveCategorias()

  const onSubmit = (data) => {

    if(data.cantidadFirmas==1){
  data.firma1=firma1

   unregister("firmante2")
   unregister("firmante3")
   unregister("firmante4")
   unregister("firmante5")
   unregister("firmante2jer")
   unregister("firmante3jer")
   unregister("firmante4jer")
   unregister("firmante5jer")
   unregister("firmante2cargo")
   unregister("firmante3cargo")
   unregister("firmante4cargo")
   unregister("firmante5cargo")
    }
    else if(data.cantidadFirmas==2){
      data.firma1=firma1
      data.firma2=firma2
    unregister("firmante3")
    unregister("firmante4")
    unregister("firmante5")
   unregister("firmante3jer")
   unregister("firmante4jer")
   unregister("firmante5jer")
   unregister("firmante3cargo")
   unregister("firmante4cargo")
   unregister("firmante5cargo")

    }
    else if(data.cantidadFirmas==3){
      data.firma1=firma1
      data.firma2=firma2
      data.firma3=firma3
     
   unregister("firmante4")
   unregister("firmante5")
   unregister("firmante4jer")
   unregister("firmante5jer")
   unregister("firmante4cargo")
   unregister("firmante5cargo")
    }
    else if(data.cantidadFirmas==4){
      data.firma1=firma1
      data.firma2=firma2
      data.firma3=firma3
      data.firma4=firma4

   unregister("firmante5")
   unregister("firmante5jer")

   unregister("firmante5cargo")
    }
    else if(data.cantidadFirmas==5){
      data.firma1=firma1
      data.firma2=firma2
      data.firma3=firma3
      data.firma4=firma4
      data.firma5=firma5
    }
crearNuevoCurso(data)
    }

  
 
    const crearNuevoCurso=async(proceso)=>{
   
      const client = axios.create({
          baseURL:"https://wwwa.isepsantafe.edu.ar/expedientes/certificados/crearCurso"
          });
          try {
            proceso.estado="activo"
            proceso.api="https://wwwa.isepsantafe.edu.ar/expedientes"
           
           const creando= await client.post('/',proceso)
           if(creando){
           alert("Etapa "+proceso.nombreCurso+" creado correctamente")
           props.myID("dale")
           }
           
            }
          catch (error) {}
     
    }

 const vistaPrevia=()=>{
  alert("Te genero la vista previa")
 }
  const cantidadFirmas=watch("cantidadFirmas")

  return (
    <div>
     
  
  <div style={{display:'flex',flexWrap:"wrap",textAlign:"center",gridRowEnd:"-moz-initial",width:"100%",  padding:"15px",margin:"10px",backgroundColor:"yellow", color:"black"}}>
         
      <h3>Usuario Logueado:{" "+usuario.apellido +", "+usuario.nombre} </h3>
      <h3 style={{marginLeft:"20px"}}>{hoy}</h3>
        <h3 style={{marginLeft:"20px"}}>Division:{devuelveDependenciaConstultada().filter(categoria=>categoria.id==usuario.destino)[0].descripcion} </h3>            </div><div>






      <form className='form__formulario' onSubmit={handleSubmit(onSubmit)}>
        <section className="datos-personales">

<div id='escudos'>
  

<div >
  <hr/>
  <h4>Datos Generales del Curso</h4>

<div class='input-group-text'>
<div class="form-floating mb-3 ml-3">
          <input  type="text" style={{minWidth:"350px"}} class="form-control" id="tituloIniciador" placeholder="tituloIniciador" 
          {...register('nombreCurso', {
          maxlength:"1024",
          required: true,
          minLength: 3,
          })}/>
          <label for="tituloIniciador">Nombre del Curso</label></div>
        
<div class="form-floating mb-3 ml-3">

<input  type="text" style={{minWidth:"200px"}} class="form-control" id="floatingTitulo" placeholder="floatingTitulo" 
{...register('reginterno', {
maxlength:"1024",
required: true,
minLength: 6,
})}/>
   <label for="floatingTitulo">Registro Interno</label>

</div>

<div class="form-floating mb-3 ml-3">
<input  type="text" style={{minWidth:"200px"}} class="form-control" id="floatingTitulo" placeholder="floatingTitulo" 
{...register('ciudadCertificado', {
maxlength:"1024",
required: true,
minLength: 3,
})}/>
   <label for="floatingTitulo">Ciudad de Certificado</label>
   </div>
   

          </div> 
          <div class='input-group-text'>
          <div class="form-floating mb-3 ml-3">
<input  type="number" style={{width:"180px"}} class="form-control" id="floatingTitulo" placeholder="floatingTitulo" 
{...register('cantidadHoras', {
maxlength:"1024",
required: true,
minLength: 1,
})}/>
   <label for="floatingTitulo">Cantidad Horas</label>
   </div>
          <div class="form-floating mb-3 ml-3" style={{width:"120px",minWidth:"100px"}}>
 
 <select class="form-select" id="tieneExptAnterior" aria-label="tieneExptAnterior"
 {...register('tipoHoras', {
  required: true
  })}>
  <option selected value="Cátedras">Cátedras</option>
   <option value="Reloj">Reloj</option>
   

</select>
<label for="tieneExptAnterior" >Tipo de Horas</label></div>

<div class="form-floating mb-3 ml-3">

<input  type="text" style={{minWidth:"200px"}} class="form-control" id="floatingTitulo" placeholder="floatingTitulo" 
{...register('resolucionCertificado', {
maxlength:"1024",
required: true,
minLength: 1,
})}/>
   <label for="floatingTitulo">Resolución</label>

</div>

  </div> 
          <div class='input-group-text'>
                 
          <div class="form-floating mb-3 ml-3">

<input  type="text" style={{minWidth:"200px"}} class="form-control" id="floatingTitulo" placeholder="floatingTitulo" 
{...register('tituloCertificado', {
maxlength:"1024",
required: true,
minLength: 3,
})}/>
   <label for="floatingTitulo">Titulo en el certificado</label>

</div>
          <div class="form-floating mb-3"style={{width:"180px"}} >
      <input  type="date"id="fechaCursado" class="form-control" placeholder="fechaCursado" 
      {...register('fechacursado', {
        required:true
       })}/>
      <label for="fechaCursado">Fecha Cursado</label>
    </div>
    <div class="form-floating mb-3"style={{width:"235px"}} >
      <input type="date" class="form-control" id="floatingfechaMinisterio" placeholder="fechaMinisterio" 
      {...register('fechaciudadCertificado', {
        minLength: 3,
      })}/>
      <label for="floatingfechaMinisterio">Fecha de extención del Certificado</label>
    </div>
    </div>
</div></div> 


                   
<hr/>
  <h4>Escudos</h4>
  <div class='input-group-text' >
        <div class="form-floating mb-3 ml-3" style={{width:"220px",minWidth:"200px"}}>
 
 <select class="form-select" id="tieneExptAnterior" aria-label="tieneExptAnterior"
 {...register('escudo1', {
  required: true
  })}>
   <option selected value="Logo">ISeP</option>
   <option value="ministerio">Ministerio</option>
   
  

</select>
<label for="tieneExptAnterior" >Escudo Principal</label></div>
 
<div class="form-floating mb-3 ml-3" style={{width:"250px",minWidth:"250px"}}>
 
 <select class="form-select" id="tieneExptAnterior" aria-label="tieneExptAnterior"
 {...register('escudo2', {
  required: true
  })}>
  <option  value="Logo">ISeP</option>
   <option value="escPol">Escuela de Policía</option>
   <option value="escEsp">Escuela Superior</option>
   <option value="escSup">Escuela de Especialidades</option>
   <option value="escInv">Escuela de Investigaciones</option>
   <option value="policia">Policía de Santa Fe</option>
   <option selected value="none">Solo el del ISeP</option>

</select>
<label for="tieneExptAnterior" >Escudo Secundario</label></div></div>
          <div >
            <hr/>
            <h4>Firmas</h4>
          <div class="form-floating mb-3 ml-3" style={{width:"200px"}}>
 
 <select  class="form-select" id="tieneExptAnterior" aria-label="tieneExptAnterior"
 {...register('cantidadFirmas', {
  required: true
  })}>
   <option value="1">1</option>
   <option value="2">2</option>
   <option value="3">3</option>
   <option value="4">4</option>
   <option value="5">5</option>
  

</select>
<label for="tieneExptAnterior" >Cuantas personas firman</label></div>
          <div class='input-group-text' >
          
          <div class="form-floating mb-3"style={{width:"200px", marginRight:"10px"}}>
         
         <input type="text" class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('firmante1', {
           minLength: 1,
           required:true
          
         })}/>
         <label for="floatingIniciador">Firmante Principal</label>
         </div>
         <div class="form-floating mb-3"style={{width:"200px", marginRight:"10px"}}>
         
         <input type="text" class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('firmante1jer', {
           minLength: 1,
           required:true
          
         })}/>
         <label for="floatingIniciador">Jerarquia Fir Ppal</label>
         </div>
         <div class="form-floating mb-3"style={{width:"200px", marginRight:"10px"}}>
         
         <input type="text" class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('firmante1cargo', {
           minLength: 1,
           required:true
          
         })}/>
         <label for="floatingIniciador">Cargo Fir Ppal</label>
         </div>
         <Imagen64 myaID={(value2)=>setFirma1(value2)} />
</div>
{cantidadFirmas>="2"&&(<><div class='input-group-text' id='segundaFirma' >
          <div class="form-floating mb-3"style={{width:"200px", marginRight:"10px"}}>
         
         <input type="text" class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('firmante2', {
           minLength: 1,

          
         })}/>
         <label for="floatingIniciador">Firmante Secundario</label>
         </div>
         <div class="form-floating mb-3"style={{width:"200px", marginRight:"10px"}}>
         
         <input type="text" class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('firmante2jer', {
           minLength: 1,

          
         })}/>
         <label for="floatingIniciador">Jerarquia Fir Sec</label>
         </div>
         <div class="form-floating mb-3"style={{width:"200px", marginRight:"10px"}}>
         
         <input type="text" class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('firmante2cargo', {
           minLength: 1,

          
         })}/>
         <label for="floatingIniciador">Cargo Fir Sec</label>
         </div>
         <Imagen64 myaID={(value2)=>setFirma2(value2)} />
</div></>)}
{cantidadFirmas>="3"&&(<><div class='input-group-text' >
          <div class="form-floating mb-3"style={{width:"200px", marginRight:"10px"}}>
         
         <input type="text" class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('firmante3', {
           minLength: 1,

          
         })}/>
         <label for="floatingIniciador">Firmante Terciario</label>
         </div>
         <div class="form-floating mb-3"style={{width:"200px", marginRight:"10px"}}>
         
         <input type="text" class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('firmante3jer', {
           minLength: 1,

          
         })}/>
         <label for="floatingIniciador">Jerarquia Fir Ter</label>
         </div>
         <div class="form-floating mb-3"style={{width:"200px", marginRight:"10px"}}>
         
         <input type="text" class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('firmante3cargo', {
           minLength: 1,

          
         })}/>
         <label for="floatingIniciador">Cargo Fir Ter</label>
         </div> <Imagen64 myaID={(value2)=>setFirma3(value2)} />
</div>
</>)}
{cantidadFirmas>="4"&&(<><div class='input-group-text' >
          <div class="form-floating mb-3"style={{width:"200px", marginRight:"10px"}}>
         
         <input type="text" class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('firmante4', {
           minLength: 1,

          
         })}/>
         <label for="floatingIniciador">Firmante Cuarto</label>
         </div>
         <div class="form-floating mb-3"style={{width:"200px", marginRight:"10px"}}>
         
         <input type="text" class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('firmante4jer', {
           minLength: 1,

          
         })}/>
         <label for="floatingIniciador">Jerarquia Fir Cuar</label>
         </div>
         <div class="form-floating mb-3"style={{width:"200px", marginRight:"10px"}}>
         
         <input type="text" class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('firmante4cargo', {
           minLength: 1,

          
         })}/>
         <label for="floatingIniciador">Cargo Fir Cuar</label>
         </div> <Imagen64 myaID={(value2)=>setFirma4(value2)} />
</div>
</>)}
{cantidadFirmas>="5"&&(<><div class='input-group-text' >
          <div class="form-floating mb-3"style={{width:"200px", marginRight:"10px"}}>
         
         <input type="text" class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('firmante5', {
           minLength: 1,

          
         })}/>
         <label for="floatingIniciador">Firmante Quinto</label>
         </div>
         <div class="form-floating mb-3"style={{width:"200px", marginRight:"10px"}}>
         
         <input type="text" class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('firmante5jer', {
           minLength: 1,

          
         })}/>
         <label for="floatingIniciador">Jerarquia Fir Quin</label>
         </div>
         <div class="form-floating mb-3"style={{width:"200px", marginRight:"10px"}}>
         
         <input type="text" class="form-control" id="floatingIniciador" placeholder="iniciador" 
         {...register('firmante5cargo', {
           minLength: 1,

          
         })}/>
         <label for="floatingIniciador">Cargo Fir Quin</label>
         </div> <Imagen64 myaID={(value2)=>setFirma5(value2)} />
</div>
</>)}
          <div>
          
          </div>
            
          </div>
                    <div className="contenedor-boton" >

        
<input type="submit" disabled={disableBotonFinalizar} value="Finalizar"  className="btn btn-success" />
<input type="button" onClick={vistaPrevia} value="Vista Previa"  className="btn btn-primary" />
<input type="button" value="Cancelar"  className="btn btn-warning" onClick={()=>{props.myID("dale")}} />

</div>
   
        </section>

        </form></div>
     
    </div>
  );
};
export default FormNuevoExpte;
