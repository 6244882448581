import Cookies from 'universal-cookie';
function ModuloDevuelveCursos (props) {
  const cookies=new Cookies();

  
        const usuarioPrivilegios = (
    
          <>
    
          <ol>
          {!props.cosas?"":props.cosas.map((cursos) =><div className='btn-group'>
          <button className='btn btn-primary' onClick={()=>props.myID(cursos)}>
          <tr key={cursos.id}> 
            
          <th > 
            {cursos.tituloCertificado}
    
     </th>
     
            </tr>
            </button>
            </div>)}
          </ol></>
    
    
      );  
      return (
        <div>
          
         {usuarioPrivilegios}
       
        </div>
      );
    };
export default ModuloDevuelveCursos;
