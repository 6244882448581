import React , { useState }from "react";
import Child from '../tools/Child'
import axios from 'axios';
import { useForm } from 'react-hook-form';
import conviertePresentePostulante from "../adaptadores/conviertePresentePostulante";
import swal from 'sweetalert'
import ModuloDevuelvePresentes from "./devuelvePresentes";
import ObtieneDatosCookies from '../tools/obtieneDatosCookiesUsuario';
import convierteAnulaPresentePostulante from '../adaptadores/convierteLogAnulaPresentePostulante'
import { CSVLink } from "react-csv";

import { Spinner } from 'reactstrap';
import devuelveDepartamento from '../tools/devuelveDepartamento'
import {useNavigate} from 'react-router-dom';
import obtieneDatos from '../tools/obtieneDatosCookiesUsuario'
import DevuelveLegajoInscripto from "../modulos/DevuelveLegajoInscripto";
import Modal from 'react-modal';
function DevuelveInscriptos (props) {
  const Usuario=obtieneDatos()
  if(Usuario.destino<="0"||!Usuario||Usuario.destino==undefined){
    alert("Acceso no Autorizado SE REGRESA AL INICIO")
     window.location.href='./';
     
  } 
  const [LectorOn,setOn]=useState(false)
  const [texto,setTexto]=useState("Encender Camara QR");
  const[muestroPresentes,setMuestroPresentes]=useState(false)
  const[presentes,setPresentes]=useState({})
  const[seleccionado,setSeleccionado]=useState(false)
  const [presentesTotal,setPresentesTotal]=useState({})
  const [tituloDia,setTituloDia]=useState("")
  const [cargando, setCargando] = useState(false);
  const [modalLegajosIsOpen,setLegajoIsOpen]=useState(false)
  const usuarioLoguead=ObtieneDatosCookies()
const postulantes=props.datos
const proceso=props.proceso
const etapa=props.etapa
  const {
    register,
    formState: { errors },
    watch,
    handleSubmit,
  } = useForm({});
  const navitate = useNavigate();
  const capturoQR=(valor)=>{
  try { 

    const elemento=valor.split("-")[0]
   
    redirecciona(props.datos.data.find(x=>x.id==elemento).dni)
  } catch (error) {
    alert("Codigo Qr Inexistente")
  }}

    const devuelvePresentes2024=async() => {
      setCargando(true)
      setMuestroPresentes(false)
      setSeleccionado(false)
      setTituloDia("Presentes Totales")
    
    const client = axios.create({
    baseURL: 'https://wwwa.isepsantafe.edu.ar/expedientes/procesos/devuelvePresente'
    });
    try {
     const presentes= await client.post('/'+etapa.apiEtapa,{"fecha":"todos"})
     if(presentes){
      setPresentes(presentes.data)
      setMuestroPresentes(true)
      setCargando(false)
     }
     
     
     
      }
    catch (error) {}}                       

    const devuelvePresenteshoy=async(fecha) => {
      setCargando(true)
      setSeleccionado(true)
      var dia = new Date();

      var diareal = new Date();
    
      const parametroDia=dia.toISOString().slice(0,10);

      setTituloDia("Presentes del dia "+diareal.toLocaleDateString())
      const hoy=dia.toISOString().slice(0,10)

      const client = axios.create({
      baseURL: 'https://wwwa.isepsantafe.edu.ar/expedientes/procesos/devuelvePresente'
      });
      try {
      if(fecha==""){ const respuesta=await client.post('/'+etapa.apiEtapa,{"fecha":dia})
        if(respuesta){
         setPresentes(respuesta.data)
         setMuestroPresentes(true)
         setCargando(false)}else{
          
         }
       
   
       }else{
        const respuesta=await client.post('/'+etapa.apiEtapa,{"fecha":fecha})
        if(respuesta){
         setPresentes(respuesta.data)
         setMuestroPresentes(true)
         setCargando(false)}else{
          
         }
       }
        }
      catch (error) {}}       
   



    const daPresentePostulanteAPI=async(Infor) => {
      const fecha=new Date()
      if(Usuario.destino<="0"||!Usuario||Usuario.destino==undefined){
        alert("Acceso no Autorizado SE REGRESA AL INICIO")
         window.location.href='./';
      
      }else{
        if(LectorOn){
          encenderCamara()
        }
        try {
          const fecha=new Date()
         
          const actualizaPresente=await axios.post('https://wwwa.isepsantafe.edu.ar/expedientes/procesos/actualizaPresente/'+etapa.apiEtapa,{"dniPostulante":Infor.dni,"idUsuario":Usuario.id,"fechaPresente":fecha, "idSede":Usuario.sedeID})
          if(actualizaPresente){
            
            resetForm()
            respuestaMessage(actualizaPresente.data,Infor)
            if(muestroPresentes){
              devuelvePresenteshoy()
            }
            setCargando(false)
           
          
          }
         }
        catch (error) 
        { console.log(error)
          alert(error)
        } 

      }
     }



const respuestaMessage=async(respuesta,info)=>{

  if(respuesta==true){
    await swal(
      {
        title:"Presente exitoso!",
        text:info.nombres+" "+info.apellido+" DNI: "+ info.dni,
        icon:"success",
        buttons:"Aceptar",
        timer: "1000"
      }
      ).then( document.getElementById("campo").focus())
  }else{
    await swal(
      {
        title:"Postulante ya registrado como Presente!",
        text:info.nombres+" "+info.apellido+" DNI: "+ info.dni,
        timer: "2000",
        icon:"warning",
        buttons:"aceptar"
      }
      ).then( document.getElementById("campo").focus())
  }}


const logueaLaAnulaPresente=async(dato,etapa)=>{
  
  alert("Presente Anulado!")

    try {
      await axios.post('https://wwwa.isepsantafe.edu.ar/expedientes/procesos/loganula/',convierteAnulaPresentePostulante(dato,props)).then(setMuestroPresentes(false)).then(devuelvePresentes2024()).then(setMuestroPresentes(true))
     }
    catch (error) { console.log(error)
      alert(error)} }


const preguntaAnula=(dato)=>{

  const selccion = window.confirm("¿Desea eliminar el presente de "+postulantes.data.find(x=>x.dni==dato.dniInscripto).apellido+" ?");

  if(selccion){
    anulaPresente(dato,props.etapa.apiEtapa)
  }; 
}
const anulaPresente=async(dato,etapa)=>{
      try {

        await axios.post('https://wwwa.isepsantafe.edu.ar/expedientes/procesos/anulaPresente/'+etapa,{"idInscripto":dato.idInscripto}).then(logueaLaAnulaPresente(dato.idInscripto,etapa))
       }
      catch (error) { console.log(error)
        alert("algo fallo al anular el presente!")}
      }


  const encenderCamara=()=>
  {
   
    setOn(!LectorOn)
    if(LectorOn){
      setTexto("Encender Camara QR")
    }else{
      setTexto("Apagar Camara QR")
    }
  }
  
  
  const redirecciona=(dato)=>{ //funcion que busca en el array de los postulantes
  try {

    if(Usuario.destino<="0"||!Usuario||Usuario.destino==undefined){
      alert("Acceso no Autorizado SE REGRESA AL INICIO")
       window.location.href='./';
       
    }
    let postulante={}
    if(dato)
    {
     postulante=props.datos.data.find(x=>x.dni==dato)
     if(postulante&&postulante.id>0){
     daPresentePostulanteAPI(postulante)
      }
      else{
      alert("No se pudo dar presente postulante inexistente")
    }
    }else
    {
      alert("No se pudo dar presente postulante inexistente")
    }
    
  } catch (error) {alert("Ocurrio un error ")}
}


function sumarDias(fecha, dias){
  fecha.setDate(fecha.getDate() + dias);
  return fecha;
}

  const onSubmit = (parametroInscripto) => {
   if(parametroInscripto&&parametroInscripto.inscripto>=0){
    redirecciona(parametroInscripto.inscripto)}}
  
  const resetForm =()=>{
    document.getElementById("create-course-form").reset();
  }


  const headers = [
    { label: "Nro Control", key: "id" },
    { label: "Inscripto ID", key: "idInscripto" },
    { label: "DNI", key: "dniInscripto" },
    { label: "Apellido", key: "apellidoInscripto" },
    { label: "Nombres", key: "nombresInscripto" },
    { label: "Genero", key: "generoInscripto" },
    { label: "Usuario ID", key: "idUsuario" },
    { label: "Sede ID", key: "idSede" },
    { label: "Fecha y hora", key: "createdAt" },
    { label: "Control", key: "updatedAt" }
  ];
  const headers2= [
    { label: "Nro Control", key: "id" },
    { label: "DNI", key: "dni" },
    { label: "Email", key: "email" },
    { label: "cuil", key: "cuil" },
    { label: "Nombres", key: "nombres" },
    { label: "Apellido", key: "apellido" },
    { label: "Genero", key: "genero" },
    { label: "Estado Civil", key: "estadoCivil" },
    { label: "Fecha de Nacimiento", key: "fechaDeNacimiento" },
    { label: "Provincia de Nacimiento", key: "provinciaNacimiento" },
    { label: "Lugar de Nacimiento", key: "lugarDeNacimiento" },
    { label: "Apellido y Nombre Madre", key: "apellidoNombreMadre" },
    { label: "Apellido y Nombre Padre", key: "apellidoNombrePadre" },
    { label: "Reside en la Provincia", key: "resideProvincia" },
    { label: "En caso de no residir", key: "noResideProvincia" },
    { label: "Localidad", key: "localidad" },
    { label: "Departamento", key: "departamento" },
    { label: "Domicilio", key: "domicilio" },
    { label: "Telefono 1", key: "telefono_1" },
    { label: "Telefono 2", key: "telefono_2" },
    { label: "Comisaria", key: "comisaria" },
    { label: "Nivel de Estudio", key: "nivelEstudio" },
    { label: "Titulo Secundario", key: "tituloSecundario" },
    { label: "Fecha Titulo Secundario", key: "fechaTituloSecundario" },
    { label: "Titulo Superior", key: "tituloSuperior" },
    { label: "Fecha Titulo Superior", key: "fechaTituloSuperior" },
    { label: "Pregunta 1", key: "pregunta1" },
    { label: "Pregunta 2", key: "pregunta2" },
    { label: "Pregunta 3", key: "pregunta3" },
    { label: "Pregunta 4", key: "pregunta4" },
    { label: "Inscripto en Fecha", key: "createdAt" },
    { label: "Fecha Control", key: "updatedAt" }
  ];
const quienSoy=(valor)=>{
  const persona=props.datos.find(x=>x.dni==valor)

swal({
  title:persona.nombres+" "+persona.apellido,
  text:"Nro de Telefono: "+persona.telefono_1+" DNI: "+ persona.dni+" Domicilio: "+ persona.domicilio + " de la ciudad de "+persona.localidad+" Departamento " + devuelveDepartamento(persona.departamento),
  buttons:"Aceptar"
})
}

const customStyles = {
  content: {
    top: '50%',
  left: '50%',
  right: 'auto',
  bottom: 'auto',
  marginRight: '-50%',
  transform: 'translate(-50%, -50%)',
  width:"auto",
  height:"auto"

  },
};


function afterOpenModal() {
  // references are now sync'd and can be accessed.
  
}
function closeModalLegajo() {
  setLegajoIsOpen(false);

}
 return(
      <>

      <div className="grid-container">
      <Modal
        isOpen={modalLegajosIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModalLegajo}
        style={customStyles}
        ariaHideApp={false}
      >
       <div className=" btn-group"> <button className="btn btn-danger" style={{display:"flex",alignContent:"flex-end"}}onClick={closeModalLegajo}>x </button> <a style={{alignSelf:"center"}}><strong>Reimpresion de Legajos:</strong></a>
      </div>
       
     
         
      </Modal>
        <div className="datosTotales">
       
        <h4>Registro de Asistencia Postulantes PROCESO: {proceso.proceso}</h4>
        <h4>Total de Postulantes inscriptos:{postulantes.data.length}</h4>
      <CSVLink data={postulantes.data} filename={"Listado Postulantes "+proceso.proceso}
       className="btn btn-primary"target="_blank" headers={headers2} separator={";"}>
      Bajar listado total de Inscriptos CSV</CSVLink>

      
   
      </div>
     
      {etapa.estadoEtapa=="Activo"&&Usuario.destino=="7"||etapa.estadoEtapa=="Activo"&&Usuario.destino=="9"?<>

      <div className="capturaPresentes" style={{color:"black"}}>
       <h4>Dar Presentes en {props.etapa.nombreEtapa}</h4>
      <button className="btn btn-primary" onClick={encenderCamara}>{texto}</button>
      

         {LectorOn&&<><div className="camara"><Child direccion={(value) => capturoQR(value)} /></div></>}
         
     
      <form onSubmit={handleSubmit(onSubmit)} id="create-course-form">
    

      <input
            type="number"
            id="campo"
            placeholder="Ingrese el nro de DNI"
            {...register('inscripto', {
              required: true,
            })}
          />
          <div>
          <input className="btn btn-primary" type="submit"  value="Dar presente!"  style={{backgroundColor:"#257b0aa1"}}/>
          </div>
      


      </form>
       
      </div></>:""}

      <div className="devuelvePresentes"tyle={{display:"flex",maxHeight:"450px",overflow:"scroll",backgroundColor:"#aaaaaa", borderColor:"black",minWidth:"365px"}}> 
      <h3 style={{color:"black"}}>Asistencias</h3>
      
      <button className="btn btn-primary" onClick={()=>devuelvePresenteshoy("")}>De la Fecha</button>
      <button className="btn btn-primary" onClick={()=>devuelvePresenteshoy("2024/07/28")}>De otro Dia</button>
        <button className="btn btn-primary" onClick={devuelvePresentes2024}>Totales</button>
        {cargando?(<Spinner color='priority'/>):""}<h1></h1>
      {muestroPresentes&&presentes[0]&&<h3>{tituloDia}: {presentes.length}</h3>} 
      {muestroPresentes&&presentes[0]&&<h5 style={{color:"darkblue"}}>Rosario: {presentes.filter(x=>x.idSede==2).length+" "}Recreo: {presentes.filter(x=>x.idSede==3).length}</h5>} 
     
      {muestroPresentes&&presentes[0]?(<>

      <div style={{maxHeight:"450px",overflow:"scroll",backgroundColor:"#aaaaaa", borderColor:"black",minWidth:"340px"}}>
      
      <ModuloDevuelvePresentes  nivel={usuarioLoguead.nivel}cosas={presentes} myID={(value)=>preguntaAnula(value,"1")}myDNI={(value)=>quienSoy(value )}/>
      </div>
      

      <div > 
        <CSVLink data={presentes} filename={"Listado "+proceso.proceso + etapa.nombreEtapa+tituloDia}className="btn btn-primary"separator={";"}
      target="_blank" headers={headers}> Bajar listado Presentes</CSVLink></div>
     </>
      
      ):<p>No hay Asistencias Seleccionadas</p>} 
      </div>
     </div>
    
     </>
    )}export default DevuelveInscriptos