import React , { useState }from "react";
import { useForm } from 'react-hook-form';
import '../estilos/inscriptos.css'
import { PDFDownloadLink } from '@react-pdf/renderer';
import seteaPostulante from '../Ingresos/seteaCokiePostulante'

import Legajo from '../Ingresos/generaLegajo'
function DevuelveLegajoInscripto(props) {

  const[seleccionado,setSeleccionado]=useState(false)

 
 
const BD=props.Datos
  const {
    register,
    formState: {errors},
    handleSubmit,
  } = useForm({});
  



  const onSubmit = async(parametroInscripto) => {

 imprime(BD.filter(x=>x.dni===parametroInscripto.dni)).then(resetForm())
  
  
  }
  
  const resetForm =()=>{
    document.getElementById("create-course-form").reset();
  }


const imprime=async(mirache)=>{
 if(mirache[0]!=null){
  console.log(mirache[0])
  setSeleccionado(mirache[0])
  seteaPostulante(mirache[0])
 }else{
  alert("Legajo no encontrado intente en otro proceso!!!")
 }


}
const volver=()=>
{
 
}
 return(
      <>

      <div className="grid-container">
      
        <div className="datosTotales">
       
        <h4>Menu de Impresión de Legajos</h4>

      </div>
      

      <div className="capturaPresentes">

      
     
      <form onSubmit={handleSubmit(onSubmit)} id="create-course-form">
    

      <input
            type="number"
            id="campo"
            placeholder="Ingrese el nro de DNI"
            {...register('dni', {
              required: true,
            })}
          />
          <div>
          <input className="btn btn-primary" type="submit"  value="Consultar"  style={{backgroundColor:"#257b0aa1"}}/>
          </div>
      


      </form>
      </div>
{!seleccionado?"":
<>
<button className="btn btn-danger" style={{width:"10%",alignItems:"right"}} onClick={()=>{setSeleccionado(null)}}>x</button>

<h2>Postulante</h2><h2>{seleccionado.nombres + " "+seleccionado.apellido}</h2>
<PDFDownloadLink document={<Legajo />} fileName={"Legajo de "+seleccionado.apellido}>
<input type="button" value="Guardar su legajo"  id="imprime"  />
 </PDFDownloadLink>

 </>
}
     
     </div>
     <div className="footer">
        <input style={{width:"100%"}} className="btn btn-warning" type="button" value="Volver" onClick={()=>props.myID("dale")}/>
        </div>
     </>
    )}export default DevuelveLegajoInscripto